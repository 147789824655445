export const NATIONALITY = `
query nationality($id: Int!) {
  nationality(id: $id) {
    __typename
    ... on Nationality {
      id
      country
      gentile
      iso
      activo_web
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const NATIONALITIES = `
query nationalities(
  $filter: FilterNationalityInput
  $orderBy: NationalityOrderInput
  $skip: Int
  $take: Int
  $searchText: String
) {
  nationalities(
    filter: $filter
    orderBy: $orderBy
    skip: $skip
    take: $take
    searchText: $searchText
  ) {
    __typename
    ... on Nationality {
      id
      country
      gentile
      iso
      activo_web
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
