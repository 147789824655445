import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './global.less';

import { StrictMode, useEffect, useRef, useState } from 'react';
import { IConfig, IConfigApp, IEnvironment } from './interfaces/environment';
import configJson from './config.json';
import { UserService } from './services/UserService';
import { IRole } from './interfaces/role';
import { PageLoading } from '@ant-design/pro-layout';
import { ConfigProvider } from 'antd';
import { LanguageService } from './services/LanguageService';
import i18n, { defaultLanguage } from './i18n';
import GraphqlService from './services/graphql/GraphqlService';
import { IUser, IUserFirebase } from './interfaces/user';
import { Language } from './interfaces/language';
import { ContextApp } from './contexts/ContextApp';
import AppContent from './AppContent';
import { CustomMessage } from './hooks';
import { EnumsValues } from './enums/EnumsValues';
import { setupIonicReact } from '@ionic/react';
import { IDBPDatabase } from 'idb';
import { useOfflineFunctions } from './shared/useOfflineFunctions';

require('dotenv').config();

declare global {
  interface Window {
    firebaseui: any;
  }
}

const App: React.FC = () => {
  setupIonicReact();
  const [configApp] = useState<IConfigApp>(configJson);
  const [user, setUser] = useState<IUser | undefined>();
  const [roles, setRoles] = useState<IRole[]>([]);
  const [functions, setFunctions] = useState<string[]>([]);
  const [environment, setEnvironment] = useState<IEnvironment>();
  const [config, setConfig] = useState<IConfig>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const { getAntdConfig } = LanguageService();
  const [sessionExpired, setSessionExpired] = useState<boolean>(false);
  const [mainMenuCollapsed, setMainMenuCollapsed] = useState(
    window.innerWidth < 992,
  );
  const [menuBreakpointCollapsed, setMenuBreakpointCollapsed] = useState(
    window.innerWidth < 992,
  );
  const [languages, setLanguages] = useState<Language[]>([]);
  const { authenticate } = UserService();
  const { customRequest, Query } = GraphqlService();
  const [userDataFirebase, setUserDataFirebase] =
    useState<IUserFirebase | undefined>(undefined);
  const [pictureProfileUrl, setPictureProfileUrl] = useState<string>('');
  const [show2FAReminder, setShow2FAReminder] = useState<boolean>(false);

  const { showMessageError } = CustomMessage();
  const defaultEnvironmentCode = configJson.default_environment;
  const defaultEnvironment = (
    configJson.environments as {
      [key: string]: IEnvironment;
    }
  )[defaultEnvironmentCode];
  const defaultConfig = configJson.default_config;
  const indexedDatabaseRef = useRef<IDBPDatabase<unknown> | null>(null);
  const { databaseInitializing } = useOfflineFunctions();

  const checkAuth = async (): Promise<any> => {
    setShowLoading(true);
    try {
      const res = await authenticate();
      if (res) {
        let user: IUser = {
          email: res.email,
          id: res.id,
          username: res.username,
          password: '',
          firstname: res.firstname,
          lastname: res.lastname,
          roles: res.role,
          is_system_user: res.is_system_user,
          language_id: res.language_id,
          profile_id: res.profile_id,
          profile: res.profile,
          uid: res.uid,
          profile_picture_id: res.profile_picture_id,
          two_factor_auth_active: res.two_factor_auth_active,
          signature_id: res.signature_id,
          validated: res.validated,
        };
        setUser(user);
        setRoles(res.roles);
        if (res.two_factor_auth_active) {
          const validate2FA = localStorage.getItem(
            EnumsValues.LocalStorageKeys.TwoFactorValidated,
          );
          if (!validate2FA) {
            localStorage.setItem(
              EnumsValues.LocalStorageKeys.TwoFactorValidated,
              EnumsValues.TwoFactorValidatedValues.False,
            );
          }
        }

        setFunctions(res.permissions);
      }
    } catch (error: any) {
      showMessageError({
        context: 'App.checkAuth.1',
        error,
      });
    }
    setShowLoading(false);
  };

  const getLanguages = async () => {
    try {
      const data: Language[] = await customRequest({
        query: Query.languages,
      });
      setLanguages(() => data);
      if (!data.find((item) => item.language_code === i18n.language)) {
        i18n.changeLanguage(defaultLanguage);
        i18n.loadLanguages(data.map((item) => item.language_code));
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    getLanguages();
    let token = localStorage.getItem(EnumsValues.LocalStorageKeys.Token);
    if (!user && token) {
      checkAuth();
    } else {
      setShowLoading(() => false);
    }
  }, []);

  return (
    <ContextApp.Provider
      value={{
        configApp,
        user,
        setUser,
        setLoading,
        loading,
        loadingMessage,
        setLoadingMessage,
        setShowLoading,
        showLoading,
        environment: environment || defaultEnvironment,
        setEnvironment,
        roles,
        setRoles,
        functions,
        setFunctions,
        config: config || defaultConfig,
        setConfig,
        dataLoaded,
        setDataLoaded,
        checkAuth,
        mainMenuCollapsed,
        setMainMenuCollapsed,
        menuBreakpointCollapsed,
        setMenuBreakpointCollapsed,
        sessionExpired,
        setSessionExpired,
        languages,
        userDataFirebase,
        setUserDataFirebase,
        pictureProfileUrl,
        setPictureProfileUrl,
        show2FAReminder,
        setShow2FAReminder,
        indexedDatabaseRef,
      }}
    >
      <IonApp>
        <ConfigProvider locale={getAntdConfig()}>
          {!showLoading && !databaseInitializing ? (
            <IonReactRouter>
              <IonRouterOutlet>
                <StrictMode>
                  <AppContent />
                </StrictMode>
              </IonRouterOutlet>
            </IonReactRouter>
          ) : (
            <PageLoading />
          )}
        </ConfigProvider>
      </IonApp>
    </ContextApp.Provider>
  );
};

export default App;
