export const GET_TRANSFER_FORM_BY_PERSON_ID = `query getTransferFormByPersonId($person_id: Int!) {
  getTransferFormByPersonId(person_id: $person_id) {
    __typename
    ... on TransferForm {
      id
      person_id
      person {
        firstname
        lastname
      }
      plan_number
      provincial_part_number
      municipal_part_number
      unit_type_id
      unit_number
      header2
      header6
      header7
      header8
      observations
    }
    ... on ResultError {
      status_code
      message
    }
  }
}`;

export const GET_TRANSFER_FORM = `query getTransferForm($id: Int!) {
  getTransferForm(id: $id) {
    __typename
    ... on TransferForm {
      id
      person_id
      person {
        firstname
        lastname
      }
      plan_number
      provincial_part_number
      municipal_part_number
      unit_type_id
      unit_number
      header2
      header6
      header7
      header8
      observations
    }
    ... on ResultError {
      status_code
      message
    }
  }
}`;
