enum Functions {
  AppSettingRead = 'APP_SETTING_R',
  AppSettingUpdate = 'APP_SETTING_U',

  CompanyCreate = 'COMPANY_C',
  CompanyDelete = 'COMPANY_D',
  CompanyGet = 'COMPANY_G',
  CompanyList = 'COMPANY_L',
  CompanyUpadate = 'COMPANY_U',

  OperationCreate = 'OPERATION_C',
  OperationDelete = 'OPERATION_D',
  OperationGet = 'OPERATION_G',
  OperationList = 'OPERATION_L',
  OperationUpdate = 'OPERATION_U',
  OperationVehicleGet = 'OPERATION_VEHICLE_G',
  OperationVehicleList = 'OPERATION_VEHICLE_L',
  OperationVehicleUpdate = 'OPERATION_VEHICLE_U',

  PermissionGet = 'PERMISSION_G',
  PermissionList = 'PERMISSION_L',
  PermissionUpdate = 'PERMISSION_U',

  RoleCreate = 'ROLE_C',
  RoleDelete = 'ROLE_D',
  RoleGet = 'ROLE_G',
  RoleList = 'ROLE_L',
  RoleUpdate = 'ROLE_U',
  RolePermissionCreate = 'ROLE_PERMISSION_C',
  RolePermissionGet = 'ROLE_PERMISSION_G',
  RolePermissionDelete = 'ROLE_PERMISSION_D',
  RolePermissionList = 'ROLE_PERMISSION_L',
  RolePermissionUpdate = 'ROLE_PERMISSION_U',

  TraceCreate = 'TRAC_C',
  TraceGet = 'TRAC_G',
  TraceList = 'TRAC_L',

  UserCreate = 'USER_C',
  UserCompanyGet = 'USER_COMPANY_G',
  UserCompanyList = 'USER_COMPANY_L',
  UserCompanyUpdate = 'USER_COMPANY_U',
  UserDelete = 'USER_D',
  UserGet = 'USER_G',
  UserList = 'USER_L',
  UserRoleGet = 'USER_ROLE_G',
  UserRoleList = 'USER_ROLE_L',
  UserRoleUpdate = 'USER_ROLE_U',
  UserUpdate = 'USER_U',

  VehicleCreate = 'VEHICLE_C',
  VehicleCompanyGet = 'VEHICLE_COMPANY_G',
  VehicleCompanyList = 'VEHICLE_COMPANY_L',
  VehicleCompanyUpdate = 'VEHICLE_COMPANY_U',
  VehicleDelete = 'VEHICLE_D',
  VehicleGet = 'VEHICLE_G',
  VehicleList = 'VEHICLE_L',
  VehicleTypeCreate = 'VEHICLE_TYPE_C',
  VehicleTypeDelete = 'VEHICLE_TYPE_D',
  VehicleTypeGet = 'VEHICLE_TYPE_G',
  VehicleTypeList = 'VEHICLE_TYPE_L',
  VehicleTypeUpdate = 'VEHICLE_TYPE_U',
  VehicleUpdate = 'VEHICLE_U',

  UserProfileUpdate = 'USER_PROFILE_U',
  ProfilePictureCreate = 'PROFILE_PICTURE_C',
  ProfilePictureRead = 'PROFILE_PICTURE_R',
  ProfilePictureUpdate = 'PROFILE_PICTURE_U',
  ProfilePictureDelete = 'PROFILE_PICTURE_D',

  AdministrationMenuRead = 'ADMINISTRATION_MENU_R',

  PersonCreate = 'PERSON_C',
  PersonRead = 'PERSON_R',
  PersonUpdate = 'PERSON_U',
  PersonDelete = 'PERSON_D',

  PersonPictureCreate = 'PERSON_PICTURE_C',
  PersonPictureRead = 'PERSON_PICTURE_R',
  PersonPictureUpdate = 'PERSON_PICTURE_U',
  PersonPictureDelete = 'PERSON_PICTURE_D',
}

/**
 * Numeric Types
 * Name	Storage Size	Range
 * smallint	2 bytes	-32768 to +32767
 * integer	4 bytes	-2147483648 to +2147483647
 * bigint	8 bytes	-9223372036854775808 to 9223372036854775807
 */
enum MaxLengthInputs {
  SystemUser_UserName = 20,
  SystemUser_Email = 100,
  StringInputs = 200,
  NumberInputs = 20,
  NumberInputsMaxInt = 2147483647,
  NumberInputsMaxBigInt = 9223372036854775807,
  CodeSap = 4,
  Region_DeliveryTerm = 2,
}

enum LocalStorageKeys {
  RoleSelected = 'role_selected',
  TranslationsVersions = 'translation_versions',
  Translations = 'translations',
  TwoFactorValidated = 'two_factor_validated',
  Token = 'token',
  UserId = 'userId',
  TwoFAOmitted = 'TwoFAOmitted',
}

enum TwoFactorValidatedValues {
  True = 'true',
  False = 'false',
}

export enum SystemLimits {
  //Se define limite de tamaño de archivos en bytes
  /*
    Recordar que este enum debe corresponder con el de backend.
  */
  MaxSizeOfFiles = 20000000, //20 MB,
}

export enum ErrorsStatusCode {
  userIsAlreadyValidatedCode = 64,
  recordNotFoundCode = 2,
}

export enum SettingNames {
  RecoveryPasswordFrom = 'RECOVERY_PASSWORD_FROM',
  RecoveryPasswordSubject = 'RECOVERY_PASSWORD_SUBJECT',
  NodeMailerHost = 'NODEMAILER_HOST',
  NodeMailerPort = 'NODEMAILER_PORT',
  NodeMailerSecure = 'NODEMAILER_SECURE',
  NodeMailerUser = 'NODEMAILER_USER',
  NodeMailerPassword = 'NODEMAILER_PASSWORD',
  NodeMailerAccountType = 'NODEMAILER_ACCOUNT_TYPE',
  UrlSite = 'URL_SITE',
  PasswordRegex = 'PASSWORD_REGEX',
  ProfileRequired = 'PROFILE_REQUIRED',
  SecurityTokenExpiresin = 'SECURITY_TOKEN_EXPIRESIN',
  SecurityRefreshTokenExpiresin = 'SECURITY_REFRESH_TOKEN_EXPIRESIN',
  FirstLogin = 'FIRST_LOGIN',
  LimitMaxSizeFile = 'LIMIT_MAX_SIZE_FILE',
  GAClientID = 'GA_ID',
  ValidateUserByEmail = 'VALIDATE_USER_BY_EMAIL',
  UserPublicRegister = 'USERS_PUBLIC_REGISTER',
}

/* Recordar: si se quiere agregar un nuevo object store hay que realizar la declaracion correspondiente en el archivo IndexedDb.ts */
export enum ObjectStore {
  Company = 'companies',
  PostponedActions = 'postponedActions',
  Operation = 'operations',
}

export enum ExportFormat {
  xlsx = 'xlsx',
  csv = 'csv',
  txt = 'txt',
}

export enum PersonPictureType {
  DNI_Front = 1,
  DNI_Back = 2,
  transfer_form = 3,
}

export enum DocumentType {
  CertificacionDeFirmas = 1,
  Escritura = 2,
}

export enum GenderId {
  Masculino = 1,
  Femenino = 2,
  Otro = 3,
}

export enum Gender {
  Masculino = 'M',
  Femenino = 'F',
  Otro = 'X',
}

export const dniRegex = /^([MFmf]?\d{6,7}|\d{8,9})$/;

export class EnumsValues {
  static readonly Functions = Functions;

  readonly Functions = EnumsValues.Functions;

  static readonly MaxLengthInputs = MaxLengthInputs;

  readonly MaxLengthInputs = EnumsValues.MaxLengthInputs;

  static readonly LocalStorageKeys = LocalStorageKeys;

  readonly LocalStorageKeys = EnumsValues.LocalStorageKeys;

  static readonly TwoFactorValidatedValues = TwoFactorValidatedValues;

  readonly TwoFactorValidatedValues = EnumsValues.TwoFactorValidatedValues;

  static readonly SettingNames = SettingNames;

  readonly SettingNames = EnumsValues.SettingNames;

  static readonly SystemLimits = SystemLimits;

  readonly SystemLimits = EnumsValues.SystemLimits;

  static readonly ErrorsStatusCode = ErrorsStatusCode;

  readonly ErrorsStatusCode = EnumsValues.ErrorsStatusCode;

  constructor(init?: Partial<EnumsValues>) {
    Object.assign(this, init);
  }
}
