import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Select, Space } from 'antd';
import { useState } from 'react';
import './DocGeneratorPage.less';
import { IPerson } from '../../interfaces/person';
import GraphqlService from '../../services/graphql/GraphqlService';
import { Tools } from '../../shared';
import { CustomMessage } from '../../hooks';
import { DebounceSelect } from '../../components/common/DebounceSelect/DebounceSelect';
import { DocumentType } from '../../enums/EnumsValues';

interface FormValues {
  buyers: IBuyerId[];
  sellers: ISellerId[];
  document_type: number;
  person_id?: number;
}

interface IBuyerId {
  buyer_id: IPersonSelected;
}
interface ISellerId {
  seller_id: IPersonSelected;
}

interface IPersonSelected {
  key: string;
  label: string;
  value: string;
}

interface UserValue {
  label: string;
  value: string;
}

export const DocGeneratorPage = () => {
  const { customRequest, Query } = GraphqlService();
  const [formType, setFormType] = useState<number | undefined>(undefined);
  const { messageError } = CustomMessage();
  const [value, setValue] = useState<UserValue | undefined>(undefined);
  const [selectedIds, setSelectedIds] = useState<number[]>();
  const [persons, setPersons] = useState<IPerson[]>([]);

  const [form] = Form.useForm<FormValues>();
  const onFinish = (values: FormValues) => createDocx(values);

  async function fetchUserList(
    searchText: string,
    formField: 'buyer_id' | 'seller_id' | 'person',
  ): Promise<UserValue[]> {
    try {
      const data: IPerson[] = await customRequest({
        query: Query.persons,
        variables: {
          searchText,
        },
      });

      const ids = checkSelectedIds();

      let filteredData: IPerson[] = [];

      if (formField !== 'person') {
        filteredData = data.filter(
          (person: IPerson) => !ids.includes(person.id),
        );
      }

      const response = filteredData.map((person) => {
        return {
          label: `${person.document_number} - ${person.lastname} ${person.firstname}`,
          value: String(person.id),
        };
      });

      return response;
    } catch (error) {
      return [];
      // Intentional
    }
  }

  async function getPersons(ids: number[]) {
    try {
      const data: IPerson[] = await customRequest({
        query: Query.persons,
        variables: {
          filter: {
            id: ids,
          },
        },
      });
      setPersons(data);
    } catch (error) {
      // Intentional
    }
  }

  const checkSelectedIds = () => {
    const buyers = form.getFieldValue('buyers');
    let buyersIds: number[] = [];
    if (buyers[0] !== undefined) {
      buyers.forEach((buyer: IBuyerId) => {
        if (buyer !== undefined) {
          const id = buyer.buyer_id?.value;
          if (id) {
            buyersIds = [...buyersIds, parseInt(id)];
          }
        }
      });
    }

    const sellers = form.getFieldValue('sellers');
    let sellersIds: number[] = [];
    if (sellers[0] !== undefined) {
      sellers.forEach((seller: ISellerId) => {
        if (seller !== undefined) {
          const id = seller.seller_id?.value;
          if (id) {
            sellersIds = [...sellersIds, parseInt(id)];
          }
        }
      });
    }

    const ids = [...buyersIds, ...sellersIds];
    setSelectedIds(ids);
    return ids;
  };

  const onChangeDocumentType = (document_type_id: number) => {
    setFormType(document_type_id);
  };

  const createDocx = async (values: FormValues) => {
    let buyersArray: number[] = [];
    let sellersArray: number[] = [];

    buyersArray = values.buyers.map(({ buyer_id }) => Number(buyer_id.value));

    sellersArray = values.sellers.map(({ seller_id }) =>
      Number(seller_id.value),
    );

    let input: any;

    if (!buyersArray.length && !sellersArray.length) {
      return messageError({
        context: 'DocGeneratorPage.createDocx.1',
        message: 'Debe seleccionar al menos un comprador o vendedor',
      });
    }

    input = {
      buyersArray,
      sellersArray,
      person_id:
        values.document_type === DocumentType.Escritura
          ? values.person_id
          : undefined,
    };

    try {
      const response = await customRequest({
        mutation:
          values.document_type === DocumentType.CertificacionDeFirmas
            ? Query.createDocxCertificado08
            : Query.createDocxEscritura,
        variables: {
          input,
        },
      });

      if (response) {
        Tools.downloadFile({
          filename: response.filename,
          mimetype: response.mimetype,
          encoding: response.encoding,
          file: response.file,
        });
      }

      return response;
    } catch (error: any) {
      if (error.status_code && error.message) {
        return messageError({
          context: 'DocGeneratorPage.createDocx.2',
          message: error.message,
        });
      }
    }
  };

  return (
    <Form
      name="dynamic_form_nest_item"
      onFinish={onFinish}
      autoComplete="off"
      className="doc-generator-page"
      layout="vertical"
      form={form}
    >
      <h1>Generador de documento Word</h1>
      <Form.Item
        label="Tipo de documento"
        name="document_type"
        rules={[
          { required: true, message: 'Debe de seleccionar tipo de documento' },
        ]}
      >
        <Select
          options={[
            {
              id: 1,
              name: 'Certificación de firmas',
            },
            {
              id: 2,
              name: 'Escritura',
            },
          ].map((value) => ({
            label: value.name,
            value: value.id,
          }))}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          onChange={onChangeDocumentType}
          placeholder="Seleccione tipo de documento"
          allowClear
          showSearch
          optionFilterProp="label"
        />
      </Form.Item>
      <Row justify="space-between" gutter={40}>
        <Col xs={24} md={12} xl={12}>
          <h6>Vendedor/es</h6>
          <Form.List name="sellers" initialValue={[]}>
            {(fields, { add, remove }) => (
              <>
                {!fields.length &&
                  form.getFieldValue('document_type') ===
                    DocumentType.Escritura && (
                    <p style={{ color: 'red' }}>Debe seleccionar un vendedor</p>
                  )}
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'seller_id']}
                      rules={[
                        {
                          required: true,
                          message: 'Debe seleccionar vendedor',
                        },
                      ]}
                    >
                      <DebounceSelect
                        placeholder="Busque vendedor por nombre, apellido o dni..."
                        fetchOptions={(value) =>
                          fetchUserList(value, 'seller_id')
                        }
                        onChange={(newValue) => {
                          setValue(newValue as UserValue);
                          checkSelectedIds();
                        }}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        showSearch
                        allowClear
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                    <MinusCircleOutlined
                      onClick={() => {
                        remove(name);
                        checkSelectedIds();
                      }}
                    />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Agregar Vendedor
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
        <Col xs={24} md={12} xl={12}>
          <h6>Comprador/es</h6>
          <Form.List name="buyers" initialValue={[]}>
            {(fields, { add, remove }) => (
              <>
                {!fields.length &&
                  form.getFieldValue('document_type') ===
                    DocumentType.Escritura && (
                    <p style={{ color: 'red' }}>
                      Debe seleccionar un comprador
                    </p>
                  )}
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'buyer_id']}
                      rules={[
                        {
                          required: true,
                          message: 'Debe seleccionar comprador',
                        },
                      ]}
                    >
                      <DebounceSelect
                        value={value}
                        placeholder="Busque comprador por nombre, apellido o dni..."
                        fetchOptions={(value) =>
                          fetchUserList(value, 'buyer_id')
                        }
                        onChange={(newValue) => {
                          setValue(newValue as UserValue);
                          checkSelectedIds();
                        }}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        showSearch
                        allowClear
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                    <MinusCircleOutlined
                      onClick={() => {
                        remove(name);
                        checkSelectedIds();
                      }}
                    />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Agregar Comprador
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
      <Form.Item
        name="person_id"
        label="Formulario para transferencia de..."
        rules={[
          {
            required: formType === 2,
            message: 'Debe de seleccionar una persona',
          },
        ]}
        hidden={formType !== 2}
      >
        <Select
          options={persons.map((person) => ({
            label: `${person.document_number} - ${person.lastname} ${person.firstname}`,
            value: person.id,
          }))}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          placeholder="Seleccione persona de donde se obtiene el formulario para transferencia..."
          allowClear
          showSearch
          optionFilterProp="label"
          style={{ width: '100%' }}
          onFocus={() => {
            if (selectedIds) {
              getPersons(selectedIds);
            }
          }}
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Generar y descargar documento
        </Button>
      </Form.Item>
    </Form>
  );
};
