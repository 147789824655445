import {
  HomeOutlined,
  SettingOutlined,
  ControlOutlined,
  LockOutlined,
  BulbOutlined,
  TeamOutlined,
  UserAddOutlined,
  FileSyncOutlined,
  FileWordOutlined,
} from '@ant-design/icons';
import DefaultRedirectPage from '../pages/DefaultRedirect/DefaultRedirectPage';
import { EnumsValues } from '../enums/EnumsValues';
import { IRouteConfig } from '../interfaces/routeConfig';
import { Suspense, lazy } from 'react';
import { PageLoading } from '@ant-design/pro-layout';
import EmptyLayout from '../layouts/emptyLayout';
import { PersonPage } from '../pages/Person/PersonPage';
import { AddPerson } from '../pages/AddPerson/AddPerson';
import { AddTransferForm } from '../pages/AddTransferForm/AddTransferForm';
import { DocGeneratorPage } from '../pages/DocGenerator/DocGeneratorPage';

const HomePage = lazy(() => import('../pages/Home/HomePage'));
const LoginPage = lazy(() => import('../pages/Login/LoginPage'));
const PublicLayout = lazy(() => import('../layouts/publicLayout'));
const RegisteredLayout = lazy(() => import('../layouts/registered'));
const AppSettingPage = lazy(() => import('../pages/AppSetting/AppSettingPage'));
const ResetPasswordContentPage = lazy(
  () => import('../pages/ResetPassword/ResetPasswordContentPage'),
);
const SetPasswordContentPage = lazy(
  () => import('../pages/SetPassword/SetPasswordPage'),
);
const ProfilePage = lazy(() => import('../pages/Profile/ProfilePage'));
const RolePage = lazy(() => import('../pages/Role/RolePage'));
const PermissionPage = lazy(() => import('../pages/Permission/PermissionPage'));
const UserPage = lazy(() => import('../pages/User/UserPage'));
const AboutPage = lazy(() => import('../pages/About/AboutPage'));
const Reminder2FA = lazy(
  () => import('../pages/Totp2FA/Reminder2FA/Reminder2FA'),
);
const Generate2FA = lazy(
  () => import('../pages/Totp2FA/Generate2FA/Generate2FA'),
);
const Validate2FA = lazy(
  () => import('../pages/Totp2FA/Validate2FA/Validate2FA'),
);
const Config2FA = lazy(() => import('../pages/Totp2FA/Config2FA/Config2FA'));
const CompleteRegisterPage = lazy(
  () => import('../pages/CompleteRegister/CompleteRegisterPage'),
);
const RegisterPage = lazy(() => import('../pages/Register/RegisterPage'));

const routes: IRouteConfig[] = [
  {
    path: '/',
    exact: true,
    component: DefaultRedirectPage,
    key: 'defaultRedirect',
  },
  {
    path: '/app',
    component: (props: any) => (
      <Suspense fallback={PageLoading}>
        <RegisteredLayout {...props} />
      </Suspense>
    ),
    key: 'app',
    routes: [
      {
        path: '/app/home',
        component: () => (
          <Suspense fallback={PageLoading}>
            <HomePage />
          </Suspense>
        ),
        name: 'Home',
        icon: <HomeOutlined />,
        key: 'home',
      },
      {
        path: '/app/add-person',
        component: () => (
          <Suspense fallback={PageLoading}>
            <AddPerson />
          </Suspense>
        ),
        name: 'Agregar Persona',
        icon: <UserAddOutlined />,
        key: 'add-person',
      },
      {
        path: '/app/add-tranfer',
        component: () => (
          <Suspense fallback={PageLoading}>
            <AddTransferForm />
          </Suspense>
        ),
        name: 'Agregar Ficha',
        icon: <FileSyncOutlined />,
        key: 'add-tranfer',
      },
      {
        path: '/app/generate-documents',
        component: () => (
          <Suspense fallback={PageLoading}>
            <DocGeneratorPage />
          </Suspense>
        ),
        name: 'Crear documento',
        icon: <FileWordOutlined />,
        key: 'generate-documents',
      },
      {
        path: '/app/person',
        component: () => (
          <Suspense fallback={PageLoading}>
            <PersonPage />
          </Suspense>
        ),
        name: 'Personas',
        icon: <TeamOutlined />,
        key: 'person',
      },
      {
        path: '/app/config2fa',
        component: () => (
          <Suspense fallback={PageLoading}>
            <Config2FA />
          </Suspense>
        ),
        hideInMenu: true,
        name: 'Autenticación 2FA',
        icon: <LockOutlined />,
        key: 'config2fa',
      },
      {
        path: '/app/administration',
        name: 'Administración',
        component: EmptyLayout,
        icon: <ControlOutlined />,
        authority: EnumsValues.Functions.AdministrationMenuRead,
        key: 'administration',
        routes: [
          {
            path: '/app/administration/role',
            component: () => (
              <Suspense fallback={PageLoading}>
                <RolePage />
              </Suspense>
            ),
            authority: EnumsValues.Functions.UserRoleList,
            name: 'Roles',
            key: 'role',
          },
          {
            path: '/app/administration/permission',
            component: () => (
              <Suspense fallback={PageLoading}>
                <PermissionPage />
              </Suspense>
            ),
            authority: EnumsValues.Functions.PermissionList,
            name: 'Permisos',
            key: 'permission',
          },
          {
            path: '/app/administration/user',
            component: (props: any) => (
              <Suspense fallback={PageLoading}>
                <UserPage {...props} />
              </Suspense>
            ),
            authority: EnumsValues.Functions.UserRoleList,
            name: 'Usuarios',
            key: 'user',
          },
        ],
      },
      {
        path: '/app/setting',
        authority: EnumsValues.Functions.AppSettingRead,
        component: () => (
          <Suspense fallback={PageLoading}>
            <AppSettingPage />
          </Suspense>
        ),
        name: 'Configuración',
        icon: <SettingOutlined />,
        key: 'setting',
      },
      {
        path: '/app/about',
        component: () => (
          <Suspense fallback={PageLoading}>
            <AboutPage />
          </Suspense>
        ),
        name: 'About',
        icon: <BulbOutlined />,
        key: 'about',
      },
      {
        path: '/app/profile',
        // authority: EnumsValues.Functions.AppSettingRead,
        component: ProfilePage,
        name: 'Mi perfil',
        hideInMenu: true,
        key: 'profile',
      },
    ],
  },
  {
    path: '/',
    component: (props: any) => (
      <Suspense fallback={PageLoading}>
        <PublicLayout {...props} />
      </Suspense>
    ),
    key: 'publicLayout',
    routes: [
      {
        path: '/resetPassword',
        exact: true,
        component: () => (
          <Suspense fallback={PageLoading}>
            <ResetPasswordContentPage />
          </Suspense>
        ),
        key: 'resetPassword',
      },
      {
        exact: true,
        path: '/setPassword',
        component: () => (
          <Suspense fallback={PageLoading}>
            <SetPasswordContentPage />
          </Suspense>
        ),
        key: 'setPassword',
      },
      {
        exact: true,
        path: '/completeRegister',
        component: () => (
          <Suspense fallback={PageLoading}>
            <CompleteRegisterPage />
          </Suspense>
        ),
        key: 'completeRegister',
      },
      {
        path: '/login',
        exact: true,
        component: (props: any) => (
          <Suspense fallback={PageLoading}>
            <LoginPage {...props} />
          </Suspense>
        ),
        key: 'login',
      },
      {
        path: '/register',
        exact: true,
        component: () => (
          <Suspense fallback={PageLoading}>
            <RegisterPage />
          </Suspense>
        ),
        key: 'register',
      },
      {
        path: '/reminder',
        exact: true,
        component: () => (
          <Suspense fallback={PageLoading}>
            <Reminder2FA />
          </Suspense>
        ),
        key: 'reminder',
      },
      {
        path: '/generate',
        exact: true,
        component: (props: any) => (
          <Suspense fallback={PageLoading}>
            <Generate2FA {...props} />
          </Suspense>
        ),
        key: 'generate',
      },
      {
        path: '/validateTotp',
        exact: true,
        component: () => (
          <Suspense fallback={PageLoading}>
            <Validate2FA />
          </Suspense>
        ),
        key: 'validateTotp',
      },
    ],
  },
];

export default routes;
