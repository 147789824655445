import { Button, Col, Form, Input, InputNumber, Row, Select } from 'antd';
import './AddTransferForm.less';
import GraphqlService from '../../services/graphql/GraphqlService';
import { CustomMessage } from '../../hooks';
import { UploadFile } from 'antd/es/upload';
import { useState, useCallback, useEffect } from 'react';
import { IPerson } from '../../interfaces/person';
import { Tools } from '../../shared';
import { ErrorsStatusCode, PersonPictureType } from '../../enums/EnumsValues';
import { UploadChangeParam } from 'antd/lib/upload';
import { DataExtraction } from '../../components/common/DataExtraction/DataExtraction';
import { ITransferForm } from '../../interfaces/TransferForm';
import { IPersonPicture } from '../../interfaces/personPicture';
import { DebounceSelect } from '../../components/common/DebounceSelect/DebounceSelect';
import { useHistory, useLocation } from 'react-router';
const { TextArea } = Input;

interface ITransferFormValues {
  person_id: number;
  plan_number: string;
  provincial_part_number: string;
  municipal_part_number: string;
  unit_type_id: number;
  unit_number: string;
  header2: string;
  header6: string;
  header7: string;
  header8: string;
  observations: string;
}

interface IFormValues {
  person?: IPersonSelected;
  plan_number: string;
  provincial_part_number: string;
  municipal_part_number: string;
  unit_type_id: number;
  unit_number: string;
  header2: string;
  header6: string;
  header7: string;
  header8: string;
  observations: string;
}

interface IPersonSelected {
  key: string;
  label: string;
  value: string;
}

interface UserValue {
  label: string;
  value: string;
}

export const AddTransferForm = () => {
  const [form] = Form.useForm();
  const [formLoading, setFormLoading] = useState(false);
  const { Mutation, customFileRequest, customRequest, Query } =
    GraphqlService();
  const { messageError, messageCreateError, messageCreateSuccess } =
    CustomMessage();
  const [fileListTransferForm, setFileListTransferForm] =
    useState<UploadChangeParam<UploadFile<any>>>();
  const [transferFrom, setTransferFrom] = useState<ITransferForm>();
  const [showUpload, setShowUpload] = useState(false);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.state) {
      const { person }: { person: IPerson } = location.state as any;
      fetchUserList(person.document_number);
    }
  }, [location.state]);

  async function fetchUserList(searchText: string): Promise<UserValue[]> {
    try {
      const data: IPerson[] = await customRequest({
        query: Query.persons,
        variables: {
          searchText,
        },
      });

      const response = data.map((person) => {
        return {
          label: `${person.document_number} - ${person.lastname} ${person.firstname}`,
          value: String(person.id),
        };
      });

      if (response.length === 1 && location.state) {
        form.setFieldsValue({
          person: response[0],
        });
        setShowUpload(true);
      }
      return response;
    } catch (error) {
      return [];
      // Intentional
    }
  }

  const getTransferFormByPersonId = async (person_id: number) => {
    try {
      let value: ITransferForm;
      value = await customRequest({
        query: Query.getTransferFormByPersonId,
        variables: {
          person_id,
        },
      });
      setShowUpload(true);
      setTransferFrom(value);
      form.setFieldsValue({
        plan_number: value.plan_number,
        provincial_part_number: value.provincial_part_number,
        municipal_part_number: value.municipal_part_number,
        unit_type_id: value.unit_type_id,
        unit_number: value.unit_number,
        header2: value.header2,
        header6: value.header6,
        header7: value.header7,
        header8: value.header8,
        observations: value.observations,
      });
    } catch (error: any) {
      // Intentional
      if (error.status_code === ErrorsStatusCode.recordNotFoundCode) {
        setShowUpload(true);
        clearAllFieldsExceptPerson();
      }
    }
  };

  const onFinish = useCallback(
    async (value: IFormValues) => {
      let response: ITransferForm | void;
      let picture_id: number | undefined | void;
      if (!transferFrom) {
        response = await createTransferForm(value);
        if (!response) return;
      } else {
        response = await updateTransferForm(value);
        if (!response) return;
        picture_id = await getPersonPictureId(
          response.person_id,
          PersonPictureType.transfer_form,
        );
      }

      if (fileListTransferForm) {
        let imageUrl = '';
        Tools.getBase64WithCallback(
          fileListTransferForm.file.originFileObj,
          (fileUrl: string) => {
            imageUrl = fileUrl;
          },
        );

        upsertPersonPicture({
          person_id: response.person_id,
          current_person_picture_id: picture_id || undefined,
          picture_type_id: PersonPictureType.transfer_form,
          file: fileListTransferForm.file.originFileObj,
          imageUrl,
        });
      }

      clearForm();
      messageCreateSuccess({
        context: 'AddTransferForm.upsertPersonPicture.1',
      });
    },
    [fileListTransferForm, transferFrom],
  );

  const createTransferForm = async (value: IFormValues) => {
    let response: ITransferForm;
    setFormLoading(true);

    let input: ITransferFormValues;

    const person_id = Number(value.person?.value);
    delete value.person;

    input = {
      ...value,
      plan_number: String(value.plan_number),
      municipal_part_number: String(value.municipal_part_number),
      provincial_part_number: String(value.provincial_part_number),
      unit_number: String(value.unit_number),
      person_id: person_id,
    };

    try {
      response = await customRequest({
        mutation: Mutation.createTransferForm,
        variables: {
          input,
        },
      });
      setFormLoading(false);
      return response;
    } catch (error: any) {
      if (error.status_code && error.message) {
        return messageError({
          context: 'AddTransferForm.createTransferForm.1',
          message: error.message,
        });
      }
    } finally {
      setFormLoading(false);
    }
  };

  const updateTransferForm = useCallback(
    async (value: IFormValues) => {
      let response: ITransferForm;
      setFormLoading(true);

      let input: ITransferFormValues;

      const person_id = Number(value.person?.value);
      delete value.person;

      input = {
        ...value,
        plan_number: String(value.plan_number),
        municipal_part_number: String(value.municipal_part_number),
        provincial_part_number: String(value.provincial_part_number),
        unit_number: String(value.unit_number),
        person_id: person_id,
      };

      try {
        response = await customRequest({
          mutation: Mutation.updateTransferForm,
          variables: {
            input,
            id: transferFrom?.id,
          },
        });
        setFormLoading(false);
        return response;
      } catch (error: any) {
        if (error.status_code && error.message) {
          return messageError({
            context: 'AddTransferForm.updateTransferForm.1',
            message: error.message,
          });
        }
      } finally {
        setFormLoading(false);
      }
    },
    [transferFrom],
  );

  const getPersonPictureId = async (
    person_id: number,
    picture_type_id: number,
  ) => {
    if (!person_id || !picture_type_id) return;
    try {
      const data: IPersonPicture = await customRequest({
        query: Query.getPersonPicture,
        variables: { person_id, picture_type_id },
      });
      return data.id;
    } catch (error: any) {
      //Intentional
    }
  };

  const upsertPersonPicture = async (value: {
    file: any;
    person_id: number;
    picture_type_id: number;
    imageUrl: string;
    current_person_picture_id?: number;
  }) => {
    const { file, person_id, picture_type_id, current_person_picture_id } =
      value;

    try {
      await customFileRequest(
        {
          mutation: current_person_picture_id
            ? Mutation.updatePersonPicture
            : Mutation.createPersonPicture,
          variables: {
            person_id,
            picture_type_id,
            current_person_picture_id,
            file: file
              ? {
                  filename: file?.filename,
                  mimetype: file?.type,
                  encoding: 'base64',
                }
              : undefined,
          },
        },
        file
          ? [
              {
                file: file,
                path: 'variables.file',
              },
            ]
          : [],
      );
    } catch (error: any) {
      if (error.status_code && error.message) {
        return messageError({
          context: 'AddTransferForm.upsertPersonPicture.2',
          message: error.message,
        });
      }
      messageCreateError({ context: 'AddTransferForm.upsertPersonPicture.3' });
    }
  };

  const clearForm = () => {
    form.resetFields();
    setFileListTransferForm(undefined);
    setShowUpload(false);

    if (location.state) {
      history.push('/app/person');
    }
  };

  const clearAllFieldsExceptPerson = () => {
    form.setFieldsValue({
      plan_number: null,
      provincial_part_number: null,
      municipal_part_number: null,
      unit_type_id: null,
      unit_number: null,
      header2: null,
      header6: null,
      header7: null,
      header8: null,
      observations: null,
    });
    setFileListTransferForm(undefined);
  };

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      form={form}
      wrapperCol={{ span: 24 }}
      className="add-transfer-form"
    >
      <h1>Formulario de carga de ficha para transferencia</h1>
      <p>
        Seleccione una persona para cargar o editar ficha para transferencia
      </p>
      <Row justify="space-between" gutter={40}>
        <Col xs={24} md={12} xl={6}>
          <Form.Item
            rules={[{ required: true, message: 'Ingrese persona' }]}
            label="Persona"
            name="person"
          >
            <DebounceSelect
              placeholder="Busque persona por nombre, apellido o dni..."
              fetchOptions={(value) => fetchUserList(value)}
              onChange={(newValue) => {
                setFileListTransferForm(undefined);
                if (!newValue?.value) {
                  clearAllFieldsExceptPerson();
                  setTransferFrom(undefined);
                  return;
                }
                getTransferFormByPersonId(Number(newValue?.value));
              }}
              onDeselect={() => {
                setShowUpload(false);
              }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              showSearch
              allowClear
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
      </Row>
      {showUpload && (
        <Row
          justify="start"
          gutter={40}
          style={{ marginBottom: 20, paddingTop: 20 }}
        >
          <Col xs={24} md={12} xl={12}>
            <Form.Item name="transfer-from">
              <DataExtraction
                form={form}
                type="transferForm"
                title="PDF - Ficha para transferencia"
                fileList={fileListTransferForm}
                setFileList={setFileListTransferForm}
                onRemove={clearAllFieldsExceptPerson}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row justify="space-between" gutter={40}>
        <Col xs={24} md={12} xl={6}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Ingrese número de plano',
              },
            ]}
            label="Número de plano"
            name="plan_number"
          >
            <InputNumber
              placeholder="Ingrese número de plano"
              type="number"
              controls={false}
            />
          </Form.Item>
        </Col>

        <Col xs={24} md={12} xl={6}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Ingrese número de partida provincial',
              },
            ]}
            label="Número de partida provincial"
            name="provincial_part_number"
          >
            <InputNumber
              placeholder="Ingrese número de partida provincial"
              type="number"
              controls={false}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} xl={6}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Ingrese número de partida municipal',
              },
            ]}
            label="Número de partida municipal"
            name="municipal_part_number"
          >
            <InputNumber
              placeholder="Ingrese número de partida municipal"
              type="number"
              controls={false}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} xl={6}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Ingrese tipo de unidad',
              },
            ]}
            label="Tipo de unidad"
            name="unit_type_id"
          >
            <Select
              options={[
                {
                  id: 1,
                  label: 'Lote',
                },
                {
                  id: 2,
                  label: 'Unidad Funcional',
                },
                {
                  id: 3,
                  label: 'Unidad Complementaria',
                },
              ].map((type) => ({
                label: `${type.label}`,
                value: type.id,
              }))}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              placeholder="Seleccione tipo de unidad..."
              allowClear
              showSearch
              optionFilterProp="label"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="start" gutter={40}>
        <Col xs={24} md={12} xl={6}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Ingrese número de unidad',
              },
            ]}
            label="Número de unidad"
            name="unit_number"
          >
            <InputNumber
              placeholder="Ingrese número de unidad"
              type="number"
              controls={false}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="start" gutter={40}>
        <Col xs={24} md={24} xl={12}>
          <Form.Item
            rules={[{ required: true, message: 'Ingrese rubro 2' }]}
            label="Rubro 2"
            name="header2"
          >
            <TextArea
              placeholder="Ingrese rubro 2"
              minLength={1}
              maxLength={200}
              autoSize={{ minRows: 3 }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={24} xl={12}>
          <Form.Item
            rules={[{ required: true, message: 'Ingrese rubro 6' }]}
            label="Rubro 6"
            name="header6"
          >
            <TextArea
              placeholder="Ingrese rubro 6"
              minLength={1}
              maxLength={200}
              autoSize={{ minRows: 3 }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="start" gutter={40}>
        <Col xs={24} md={24} xl={12}>
          <Form.Item
            rules={[{ required: true, message: 'Ingrese rubro 7' }]}
            label="Rubro 7"
            name="header7"
          >
            <TextArea
              placeholder="Ingrese rubro 7"
              minLength={1}
              maxLength={200}
              autoSize={{ minRows: 3 }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={24} xl={12}>
          <Form.Item
            rules={[{ required: true, message: 'Ingrese rubro 8' }]}
            label="Rubro 8"
            name="header8"
          >
            <TextArea
              placeholder="Ingrese rubro 8"
              minLength={1}
              maxLength={200}
              autoSize={{ minRows: 3 }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="start" gutter={40}>
        <Col xs={24} md={24} xl={12}>
          <Form.Item
            rules={[{ required: true, message: 'Ingrese observaciones' }]}
            label="Observaciones"
            name="observations"
          >
            <TextArea
              placeholder="Ingrese observaciones"
              minLength={1}
              maxLength={200}
              autoSize={{ minRows: 3 }}
            />
          </Form.Item>
        </Col>
      </Row>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 20,
        }}
      >
        <Button type="default" onClick={() => clearForm()}>
          Limpiar formulario
        </Button>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          loading={formLoading}
        >
          Finalizar Carga
        </Button>
      </div>
    </Form>
  );
};
