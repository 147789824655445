import {
  Button,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  InputNumber,
  Row,
  Skeleton,
} from 'antd';
import './AddPerson.less';
import GraphqlService from '../../services/graphql/GraphqlService';
import { CustomMessage } from '../../hooks';
import { DataExtraction } from '../../components/common/DataExtraction/DataExtraction';
import { UploadFile } from 'antd/es/upload';
import { useEffect, useState, useCallback } from 'react';
import { FORMAT_DATE_TIME_4 } from '../../shared/MomentJS';
import Select from 'antd/es/select';
import { INationality } from '../../interfaces/nationality';
import { IGender } from '../../interfaces';
import { IPerson } from '../../interfaces/person';
import { Tools } from '../../shared';
import { PersonPictureType, dniRegex } from '../../enums/EnumsValues';
import { UploadChangeParam } from 'antd/lib/upload';
import { useHistory, useLocation } from 'react-router';
import moment from 'moment-timezone';
import imageNotFound from '../../assets/imageNotFound.jpg';
import { IPersonPicture } from '../../interfaces/personPicture';

const { TextArea } = Input;

interface IAddPersonProps {
  firstname: string;
  lastname: string;
  date_of_birth: Date;
  place_of_birth: string;
  gender_id: string;
  nationality_id: string;
  document_number: string;
  home: string;
  cuil_cuit?: string;
  phone_number?: string;
  email?: string;
}

interface IPersonAdd {
  firstname: string;
  lastname: string;
  date_of_birth: Date;
  place_of_birth: string;
  gender_id: number;
  nationality_id: number;
  document_number: string;
  home: string;
  cuil_cuit?: string;
  phone_number?: string;
  email?: string;
}

interface IDni {
  url: string;
  current_person_picture_id: number;
}

const INITIAL_STATE_DNI = {
  url: '',
  current_person_picture_id: 0,
};

export const AddPerson = () => {
  const [form] = Form.useForm();
  const [formLoading, setFormLoading] = useState(false);
  const { Mutation, Query, customFileRequest, customRequest } =
    GraphqlService();
  const {
    messageError,
    messageCreateError,
    messageCreateSuccess,
    messageUpdateSuccess,
  } = CustomMessage();
  const [fileListDniFront, setFileListDniFront] =
    useState<UploadChangeParam<UploadFile<any>>>();
  const [fileListDniBack, setFileListDniBack] =
    useState<UploadChangeParam<UploadFile<any>>>();
  const [nationalities, setNationalities] = useState<INationality[]>([]);
  const [genders, setGenders] = useState<IGender[]>([]);
  const [dniFront, setDniFront] = useState<IDni>(INITIAL_STATE_DNI);
  const [dniBack, setDniBack] = useState<IDni>(INITIAL_STATE_DNI);
  const history = useHistory();

  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      const { person }: { person: IPerson } = location.state as any;

      form.setFieldsValue({
        place_of_birth: person.place_of_birth,
        document_number: person.document_number,
        cuil_cuit: person.cuil_cuit,
        gender_id: person.gender_id,
        date_of_birth: moment(person.date_of_birth, 'YYYYMMDD'),
        nationality_id: person.nationality_id,
        firstname: person.firstname,
        lastname: person.lastname,
        home: person.home,
        phone_number: person.phone_number,
        email: person.email,
      });
    }
  }, [location]);

  const onFinish = useCallback(
    async (value: IAddPersonProps) => {
      let response;
      if (location.state) {
        response = await updatePerson(value);
      } else {
        response = await createPerson(value);
      }
      if (!response) return;

      if (fileListDniFront) {
        let imageUrl = '';
        Tools.getBase64WithCallback(
          fileListDniFront.file.originFileObj,
          (fileUrl: string) => {
            imageUrl = fileUrl;
          },
        );

        upsertPersonPicture({
          person_id: response.id,
          current_person_picture_id:
            dniFront.current_person_picture_id || undefined,
          picture_type_id: PersonPictureType.DNI_Front,
          file: fileListDniFront.file.originFileObj,
          imageUrl,
        });
      }

      if (fileListDniBack) {
        let imageUrl = '';
        Tools.getBase64WithCallback(
          fileListDniBack.file.originFileObj,
          (fileUrl: string) => {
            imageUrl = fileUrl;
          },
        );
        upsertPersonPicture({
          person_id: response.id,
          current_person_picture_id:
            dniBack.current_person_picture_id || undefined,
          picture_type_id: PersonPictureType.DNI_Back,
          file: fileListDniBack.file.originFileObj,
          imageUrl,
        });
      }
      clearForm();
    },
    [fileListDniFront, fileListDniBack, dniFront, dniBack],
  );

  const getPersonPicture = async (
    person_id: number,
    picture_type_id: number,
  ) => {
    if (!person_id || !picture_type_id) return;

    try {
      const data: IPersonPicture = await customRequest({
        query: Query.getPersonPicture,
        variables: { person_id, picture_type_id },
      });

      const url: string = Tools.getUrlOfBase64File({
        mimetype: data.mimetype,
        fileBase64: data.file,
      });
      if (picture_type_id === PersonPictureType.DNI_Front) {
        setDniFront({
          url,
          current_person_picture_id: data.id,
        });
      }
      if (picture_type_id === PersonPictureType.DNI_Back) {
        setDniBack({
          url,
          current_person_picture_id: data.id,
        });
      }
    } catch (error: any) {
      //Intentional
    }
  };

  useEffect(() => {
    if (location.state) {
      getPersonPicture(location.state.person.id, PersonPictureType.DNI_Front);
      getPersonPicture(location.state.person.id, PersonPictureType.DNI_Back);
    }
  }, []);

  const getGenders = async () => {
    try {
      const data: IGender[] = await customRequest({
        query: Query.genders,
      });
      setGenders(data);
    } catch (error) {
      // Intentional
    }
  };

  const getNationalities = async () => {
    try {
      const data: INationality[] = await customRequest({
        query: Query.nationalities,
      });
      setNationalities(data);
    } catch (error) {
      // Intentional
    }
  };

  useEffect(() => {
    getGenders();
    getNationalities();
  }, []);

  const createPerson = async (value: IAddPersonProps) => {
    let response: IPerson;
    setFormLoading(true);

    let input: IPersonAdd;

    input = {
      ...value,
      gender_id: Number(value.gender_id),
      nationality_id: Number(value.nationality_id),
      cuil_cuit: value.cuil_cuit ? String(value.cuil_cuit) : undefined,
      document_number: String(value.document_number),
      phone_number: value.phone_number ? String(value.phone_number) : undefined,
    };

    try {
      response = await customRequest({
        mutation: Mutation.createPerson,
        variables: {
          input,
        },
      });
      setFormLoading(false);
      messageCreateSuccess({
        context: 'AddPerson.createPerson.1',
      });
      return response;
    } catch (error: any) {
      if (error.status_code && error.message) {
        return messageError({
          context: 'AddPerson.createPerson.3',
          message: error.message,
        });
      }
    } finally {
      setFormLoading(false);
    }
    setFormLoading(false);
  };

  const updatePerson = async (value: IAddPersonProps) => {
    let response: IPerson;
    setFormLoading(true);

    let input: IPersonAdd;

    input = {
      ...value,
      gender_id: Number(value.gender_id),
      nationality_id: Number(value.nationality_id),
      cuil_cuit: value.cuil_cuit ? value.cuil_cuit : undefined,
      document_number: String(value.document_number),
      phone_number: value.phone_number ? String(value.phone_number) : undefined,
    };

    try {
      response = await customRequest({
        mutation: Mutation.updatePerson,
        variables: {
          id: location.state.person.id,
          input,
        },
      });
      setFormLoading(false);
      messageUpdateSuccess({
        context: 'AddPerson.updatePerson.1',
      });
      return response;
    } catch (error: any) {
      if (error.status_code && error.message) {
        return messageError({
          context: 'AddPerson.createPerson.3',
          message: error.message,
        });
      }
    } finally {
      setFormLoading(false);
    }
    setFormLoading(false);
  };

  const upsertPersonPicture = async (value: {
    file: any;
    person_id: number;
    picture_type_id: number;
    imageUrl: string;
    current_person_picture_id?: number;
  }) => {
    const { file, person_id, picture_type_id, current_person_picture_id } =
      value;

    try {
      await customFileRequest(
        {
          mutation: current_person_picture_id
            ? Mutation.updatePersonPicture
            : Mutation.createPersonPicture,
          variables: {
            person_id,
            picture_type_id,
            current_person_picture_id,
            file: file
              ? {
                  filename: file?.filename,
                  mimetype: file?.type,
                  encoding: 'base64',
                }
              : undefined,
          },
        },
        file
          ? [
              {
                file: file,
                path: 'variables.file',
              },
            ]
          : [],
      );
    } catch (error: any) {
      if (error.status_code && error.message) {
        return messageError({
          context: 'AddPerson.upsertPersonPicture.2',
          message: error.message,
        });
      }
      messageCreateError({ context: 'AddPerson.upsertPersonPicture.3' });
    }
  };

  const clearForm = () => {
    form.resetFields();
    setFileListDniFront(undefined);
    setFileListDniBack(undefined);
    if (location.state) {
      history.push({
        pathname: '/app/person',
      });
    }
  };

  const onRemove = () => {
    form.resetFields();
  };

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      form={form}
      wrapperCol={{ span: 24 }}
      className="add-person-form"
    >
      <h1>Formulario de carga de persona</h1>
      <Row
        justify="start"
        gutter={40}
        style={{ marginBottom: 20, paddingTop: 20 }}
      >
        <Col xs={24} md={12} xl={6}>
          <Form.Item name="dniF">
            <DataExtraction
              form={form}
              type="dniFront"
              title="Imagen DNI - Frontal"
              fileList={fileListDniFront}
              setFileList={setFileListDniFront}
              nationalities={nationalities}
              onRemove={onRemove}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} xl={6}>
          {location.state && !dniFront.url ? (
            <Skeleton.Image className="add-person-form__skeleton" active />
          ) : (
            <Image
              src={dniFront.url || ''}
              alt="Imagen DNI"
              preview={false}
              className="person-image-preview"
              fallback={imageNotFound}
              hidden={!dniFront.url}
            />
          )}
        </Col>
        <Col xs={24} md={12} xl={6}>
          <Form.Item name="dniB">
            <DataExtraction
              form={form}
              type="dniBack"
              title="Imagen DNI - Dorso"
              fileList={fileListDniBack}
              setFileList={setFileListDniBack}
              nationalities={nationalities}
              onRemove={onRemove}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} xl={6}>
          {location.state && !dniFront.url ? (
            <Skeleton.Image className="add-person-form__skeleton" active />
          ) : (
            <Image
              src={dniBack.url || ''}
              alt="Imagen DNI"
              preview={false}
              className="person-image-preview"
              fallback={imageNotFound}
              hidden={!dniBack.url}
            />
          )}
        </Col>
      </Row>
      <Row justify="space-between" gutter={40}>
        <Col xs={24} md={12} xl={6}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Ingrese nombre',
              },
            ]}
            label="Nombre"
            name="firstname"
          >
            <Input placeholder="Ingrese nombre" minLength={1} maxLength={200} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} xl={6}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Ingrese apellido',
              },
            ]}
            label="Apellido"
            name="lastname"
          >
            <Input
              placeholder="Ingrese apellido"
              minLength={1}
              maxLength={200}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} xl={6}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Ingrese fecha de nacimiento',
              },
            ]}
            label="Fecha de nacimiento"
            name="date_of_birth"
          >
            <DatePicker
              format={FORMAT_DATE_TIME_4}
              placeholder="Seleccione fecha"
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} xl={6}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Ingrese lugar de nacimiento',
              },
            ]}
            label="Lugar de nacimiento"
            name="place_of_birth"
          >
            <Input
              placeholder="Ingrese lugar de nacimiento"
              minLength={1}
              maxLength={200}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="start" gutter={40}>
        <Col xs={24} md={12} xl={6}>
          <Form.Item
            rules={[{ required: true, message: 'Ingrese género' }]}
            label="Género"
            name="gender_id"
          >
            <Select
              options={genders.map((gender) => ({
                label: gender.description,
                value: gender.id,
              }))}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              placeholder="Seleccione genero..."
              allowClear
              showSearch
              optionFilterProp="label"
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} xl={6}>
          <Form.Item
            rules={[{ required: true, message: 'Ingrese nacionalidad' }]}
            label="Nacionalidad"
            name="nationality_id"
          >
            <Select
              options={nationalities.map((nationality) => ({
                label: nationality.gentile,
                value: nationality.id,
              }))}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              placeholder="Seleccione nacionalidad..."
              allowClear
              showSearch
              optionFilterProp="label"
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} xl={6}>
          <Form.Item
            rules={[
              { required: true, message: 'Ingrese número de documento' },
              {
                pattern: dniRegex,
                message: 'Formato incorrecto',
              },
            ]}
            label="Número de documento"
            name="document_number"
          >
            <Input placeholder="Ingrese número de documento" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} xl={6}>
          <Form.Item
            rules={[
              {
                pattern: /^[\d]{0,20}$/,
                message: 'Máximo 20 dígitos',
              },
            ]}
            label="Número de cuil/cuit"
            name="cuil_cuit"
          >
            <InputNumber
              placeholder="Ingrese número de cuil/cuit"
              type="number"
              controls={false}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="start" gutter={40}>
        <Col xs={24} md={24} xl={12}>
          <Form.Item
            rules={[{ required: true, message: 'Ingrese domicilio completo' }]}
            label="Domicilio"
            name="home"
          >
            <TextArea
              placeholder="Ingrese domicilio completo"
              minLength={1}
              maxLength={200}
              autoSize={{ minRows: 1, maxRows: 3 }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} xl={6}>
          <Form.Item
            label="Número de teléfono"
            name="phone_number"
            rules={[
              {
                pattern: /^[\d]{0,30}$/,
                message: 'Máximo 30 dígitos',
              },
            ]}
          >
            <InputNumber
              placeholder="Ingrese número de teléfono"
              type="number"
              controls={false}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} xl={6}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: 'email',
                message: 'Debe ingresar un email válido',
              },
            ]}
          >
            <Input placeholder="Ingrese Email" minLength={1} maxLength={100} />
          </Form.Item>
        </Col>
      </Row>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 20,
        }}
      >
        <Button type="default" onClick={() => clearForm()}>
          Limpiar formulario
        </Button>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          loading={formLoading}
        >
          Finalizar Carga
        </Button>
      </div>
    </Form>
  );
};
