import { Locale } from 'antd/lib/locale-provider';
import { useTranslation } from 'react-i18next';

export const LanguageService = () => {
  const [t] = useTranslation();
  const getAntdConfig = (): Locale => ({
    locale: t('ant-design.locale'),
    Pagination: {
      items_per_page: t('ant-design.pagination.items_per_page'),
      jump_to: t('ant-design.pagination.jump_to'),
      jump_to_confirm: t('ant-design.pagination.jump_to_confirm'),
      page: t('ant-design.pagination.page (esta vacio)'),
      prev_page: t('ant-design.pagination.prev_page'),
      next_page: t('ant-design.pagination.next_page'),
      prev_5: t('ant-design.pagination.prev_5'),
      next_5: t('ant-design.pagination.next_5'),
      prev_3: t('ant-design.pagination.prev_3'),
      next_3: t('ant-design.pagination.next_3'),
    },
    DatePicker: {
      lang: {
        placeholder: t('ant-design.date-picker.placeholder'),
        yearPlaceholder: t('ant-design.date-picker.yearPlaceholder'),
        quarterPlaceholder: t('ant-design.date-picker.quarterPlaceholder'),
        monthPlaceholder: t('ant-design.date-picker.monthPlaceholder'),
        weekPlaceholder: t('ant-design.date-picker.weekPlaceholder'),
        rangePlaceholder: [
          t('ant-design.date-picker.rangePlaceholder1'),
          t('ant-design.date-picker.rangePlaceholder2'),
        ],
        rangeYearPlaceholder: [
          t('ant-design.date-picker.rangeYearPlaceholder1'),
          t('ant-design.date-picker.rangeYearPlaceholder2'),
        ],
        rangeMonthPlaceholder: [
          t('ant-design.date-picker.rangeMonthPlaceholder1'),
          t('ant-design.date-picker.rangeMonthPlaceholder2'),
        ],
        rangeWeekPlaceholder: [
          t('ant-design.date-picker.rangeWeekPlaceholder1'),
          t('ant-design.date-picker.rangeWeekPlaceholder2'),
        ],
        locale: t('ant-design.date-picker.lang.locale'),
        today: t('ant-design.date-picker.lang.today'),
        now: t('ant-design.date-picker.lang.now'),
        backToToday: t('ant-design.date-picker.lang.backToToday'),
        ok: t('ant-design.date-picker.lang.ok'),
        clear: t('ant-design.date-picker.lang.clear'),
        month: t('ant-design.date-picker.lang.month'),
        year: t('ant-design.date-picker.lang.year'),
        timeSelect: t('ant-design.date-picker.lang.timeSelect'),
        dateSelect: t('ant-design.date-picker.lang.dateSelect'),
        weekSelect: t('ant-design.date-picker.lang.weekSelect'),
        monthSelect: t('ant-design.date-picker.lang.monthSelect'),
        yearSelect: t('ant-design.date-picker.lang.yearSelect'),
        decadeSelect: t('ant-design.date-picker.lang.decadeSelect'),
        yearFormat: t('ant-design.date-picker.lang.yearFormat'),
        dateFormat: t('ant-design.date-picker.lang.dateFormat'),
        dayFormat: t('ant-design.date-picker.lang.dayFormat'),
        dateTimeFormat: t('ant-design.date-picker.lang.dateTimeFormat'),
        monthBeforeYear:
          t('ant-design.date-picker.lang.monthBeforeYear') === 'true',
        previousMonth: t('ant-design.date-picker.lang.previousMonth'),
        nextMonth: t('ant-design.date-picker.lang.nextMonth'),
        previousYear: t('ant-design.date-picker.lang.previousYear'),
        nextYear: t('ant-design.date-picker.lang.nextYear'),
        previousDecade: t('ant-design.date-picker.lang.previousDecade'),
        nextDecade: t('ant-design.date-picker.lang.nextDecade'),
        previousCentury: t('ant-design.date-picker.lang.previousCentury'),
        nextCentury: t('ant-design.date-picker.lang.nextCentury'),
      },
      timePickerLocale: {
        placeholder: t('ant-design.time-picker.placeholder'),
        rangePlaceholder: [
          t('ant-design.date-picker.time-picker-locale.rangePlaceholder1'),
          t('ant-design.date-picker.time-picker-locale.rangePlaceholder2'),
        ],
      },
    },
    TimePicker: {
      placeholder: t('ant-design.time-picker.placeholder'),
      rangePlaceholder: [
        t('ant-design.time-picker.placeholder.rangePlaceholder1'),
        t('ant-design.time-picker.placeholder.rangePlaceholder2'),
      ],
    },
    Calendar: {
      lang: {
        placeholder: t('ant-design.date-picker.placeholder'),
        yearPlaceholder: t('ant-design.date-picker.yearPlaceholder'),
        quarterPlaceholder: t('ant-design.date-picker.quarterPlaceholder'),
        monthPlaceholder: t('ant-design.date-picker.monthPlaceholder'),
        weekPlaceholder: t('ant-design.date-picker.weekPlaceholder'),
        rangePlaceholder: [
          t('ant-design.date-picker.rangePlaceholder1'),
          t('ant-design.date-picker.rangePlaceholder2'),
        ],
        rangeYearPlaceholder: [
          t('ant-design.date-picker.rangeYearPlaceholder1'),
          t('ant-design.date-picker.rangeYearPlaceholder2'),
        ],
        rangeMonthPlaceholder: [
          t('ant-design.date-picker.rangeMonthPlaceholder1'),
          t('ant-design.date-picker.rangeMonthPlaceholder2'),
        ],
        rangeWeekPlaceholder: [
          t('ant-design.date-picker.rangeWeekPlaceholder1'),
          t('ant-design.date-picker.rangeWeekPlaceholder2'),
        ],
        locale: t('ant-design.date-picker.lang.locale'),
        today: t('ant-design.date-picker.lang.today'),
        now: t('ant-design.date-picker.lang.now'),
        backToToday: t('ant-design.date-picker.lang.backToToday'),
        ok: t('ant-design.date-picker.lang.ok'),
        clear: t('ant-design.date-picker.lang.clear'),
        month: t('ant-design.date-picker.lang.month'),
        year: t('ant-design.date-picker.lang.year'),
        timeSelect: t('ant-design.date-picker.lang.timeSelect'),
        dateSelect: t('ant-design.date-picker.lang.dateSelect'),
        weekSelect: t('ant-design.date-picker.lang.weekSelect'),
        monthSelect: t('ant-design.date-picker.lang.monthSelect'),
        yearSelect: t('ant-design.date-picker.lang.yearSelect'),
        decadeSelect: t('ant-design.date-picker.lang.decadeSelect'),
        yearFormat: t('ant-design.date-picker.lang.yearFormat'),
        dateFormat: t('ant-design.date-picker.lang.dateFormat'),
        dayFormat: t('ant-design.date-picker.lang.dayFormat'),
        dateTimeFormat: t('ant-design.date-picker.lang.dateTimeFormat'),
        monthBeforeYear:
          t('ant-design.date-picker.lang.monthBeforeYear') === 'true',
        previousMonth: t('ant-design.date-picker.lang.previousMonth'),
        nextMonth: t('ant-design.date-picker.lang.nextMonth'),
        previousYear: t('ant-design.date-picker.lang.previousYear'),
        nextYear: t('ant-design.date-picker.lang.nextYear'),
        previousDecade: t('ant-design.date-picker.lang.previousDecade'),
        nextDecade: t('ant-design.date-picker.lang.nextDecade'),
        previousCentury: t('ant-design.date-picker.lang.previousCentury'),
        nextCentury: t('ant-design.date-picker.lang.nextCentury'),
      },
      timePickerLocale: {
        placeholder: t('ant-design.time-picker.placeholder'),
        rangePlaceholder: [
          t('ant-design.date-picker.time-picker-locale.rangePlaceholder1'),
          t('ant-design.date-picker.time-picker-locale.rangePlaceholder2'),
        ],
      },
    },
    global: {
      placeholder: t('ant-design.global.placeholder'),
    },
    Table: {
      filterTitle: t('ant-design.table.filterTitle'),
      filterConfirm: t('ant-design.table.filterConfirm'),
      filterReset: t('ant-design.table.filterReset'),
      filterEmptyText: t('ant-design.table.filterEmptyText'),
      emptyText: t('ant-design.table.emptyText'),
      selectAll: t('ant-design.table.selectAll'),
      selectInvert: t('ant-design.table.selectInvert'),
      selectNone: t('ant-design.table.selectNone'),
      selectionAll: t('ant-design.table.selectionAll'),
      sortTitle: t('ant-design.table.sortTitle'),
      expand: t('ant-design.table.expand'),
      collapse: t('ant-design.table.collapse'),
      triggerDesc: t('ant-design.table.triggerDesc'),
      triggerAsc: t('ant-design.table.triggerAsc'),
      cancelSort: t('ant-design.table.cancelSort'),
    },
    Modal: {
      okText: t('ant-design.modal.okText'),
      cancelText: t('ant-design.modal.cancelText'),
      justOkText: t('ant-design.modal.justOkText'),
    },
    Popconfirm: {
      okText: t('ant-design.modal.okText'),
      cancelText: t('ant-design.modal.cancelText'),
    },
    Transfer: {
      titles: [
        t('ant-design.transfer.title1'),
        t('ant-design.transfer.title2'),
      ],
      searchPlaceholder: t('ant-design.transfer.searchPlaceholder'),
      itemUnit: t('ant-design.transfer.itemUnit'),
      itemsUnit: t('ant-design.transfer.itemsUnit'),
      remove: t('ant-design.transfer.remove'),
      selectCurrent: t('ant-design.transfer.selectCurrent'),
      removeCurrent: t('ant-design.transfer.removeCurrent'),
      selectAll: t('ant-design.transfer.selectAll'),
      removeAll: t('ant-design.transfer.removeAll'),
      selectInvert: t('ant-design.transfer.selectInvert'),
    },
    Upload: {
      uploading: t('ant-design.upload.uploading'),
      removeFile: t('ant-design.upload.removeFile'),
      uploadError: t('ant-design.upload.uploadError'),
      previewFile: t('ant-design.upload.previewFile'),
      downloadFile: t('ant-design.upload.downloadFile'),
    },
    Empty: {
      description: t('ant-design.empty.description'),
    },
    Icon: {
      icon: t('ant-design.icon.icon'),
    },
    Text: {
      edit: t('ant-design.text.edit'),
      copy: t('ant-design.text.copy'),
      copied: t('ant-design.text.copied'),
      expand: t('ant-design.text.expand'),
    },
    PageHeader: {
      back: t('ant-design.pageheader.back'),
    },
    Form: {
      optional: t('ant-design.form.optional'),
      defaultValidateMessages: {
        default: t('ant-design.form.defaultValidateMessages.default'),
        required: t('ant-design.form.defaultValidateMessages.required'),
        enum: t('ant-design.form.defaultValidateMessages.enum'),
        whitespace: t('ant-design.form.defaultValidateMessages.whitespace'),
        date: {
          format: t('ant-design.form.defaultValidateMessages.date.format'),
          parse: t('ant-design.form.defaultValidateMessages.date.parse'),
          invalid: t('ant-design.form.defaultValidateMessages.date.invalid'),
        },
        types: {
          string: t('ant-design.form.defaultValidateMessages.type'),
          method: t('ant-design.form.defaultValidateMessages.type'),
          array: t('ant-design.form.defaultValidateMessages.type'),
          object: t('ant-design.form.defaultValidateMessages.type'),
          number: t('ant-design.form.defaultValidateMessages.type'),
          date: t('ant-design.form.defaultValidateMessages.type'),
          boolean: t('ant-design.form.defaultValidateMessages.type'),
          integer: t('ant-design.form.defaultValidateMessages.type'),
          float: t('ant-design.form.defaultValidateMessages.type'),
          regexp: t('ant-design.form.defaultValidateMessages.type'),
          email: t('ant-design.form.defaultValidateMessages.type'),
          url: t('ant-design.form.defaultValidateMessages.type'),
          hex: t('ant-design.form.defaultValidateMessages.type'),
        },
        string: {
          len: t('ant-design.form.defaultValidateMessages.string.len'),
          min: t('ant-design.form.defaultValidateMessages.string.min'),
          max: t('ant-design.form.defaultValidateMessages.string.max'),
          range: t('ant-design.form.defaultValidateMessages.string.range'),
        },
        number: {
          len: t('ant-design.form.defaultValidateMessages.number.len'),
          min: t('ant-design.form.defaultValidateMessages.number.min'),
          max: t('ant-design.form.defaultValidateMessages.number.max'),
          range: t('ant-design.form.defaultValidateMessages.number.range'),
        },
        array: {
          len: t('ant-design.form.defaultValidateMessages.array.len'),
          min: t('ant-design.form.defaultValidateMessages.array.min'),
          max: t('ant-design.form.defaultValidateMessages.array.max'),
          range: t('ant-design.form.defaultValidateMessages.array.range'),
        },
        pattern: {
          mismatch: t(
            'ant-design.form.defaultValidateMessages.pattern.mismatch',
          ),
        },
      },
    },
    Image: {
      preview: t('ant-design.image.preview'),
    },
  });

  return {
    getAntdConfig,
  };
};
