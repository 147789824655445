export const USERS = `
query users(
  $filter:  FilterUserInput,
  $orderBy:  UserOrderInput,
  $searchText: String,
  $skip: Int,
  $take: Int
) {
  users(
    filter: $filter
    orderBy: $orderBy
    searchText:$searchText      
    skip: $skip
    take: $take
  ) {
    __typename
    ... on User {
      id
      username
      email
      firstname
      lastname
      active
      lastAccess
      language_id
      is_system_user
      user_role{
      role{
        name
        id
           }
      }
      profile{
        id
        phone
        timezone
      }
      profile_picture_id
      two_factor_auth_active
      signature_id
      validated
    }
    ... on ResultError{
      status_code
      message
    }
  }
}
`;

export const LOGIN = `mutation login($username: String!, $password: String!) {
  login(
    username: $username, password: $password
  ) 
  {
    __typename
    ...on Auth {
      token
      user {
        id
        two_factor_auth_active
      }
    }
    ...on ResponseMessage {
      status
      response
    }
    ...on ResultError {
      status_code
      message
    }
  }
}
`;

export const LOGIN_FIREBASE = `mutation loginFirebase($uid: String!) {
  loginFirebase(
    uid: $uid
  ) 
  {
    __typename
    ...on Auth {
      token
      user {
        id
      }
    }
    ...on ResponseMessage {
      status
      response
    }
    ...on ResultError {
      status_code
      message
    }
  }
}
`;

export const AUTH = `
query authenticate {
  authenticate {
    __typename
    ... on AuthOutput {
      id
      username
      firstname
      lastname
      email
      roles {
        id
        name
      }
      permissions 
			profile_id
      profile{
        id
        phone
        timezone
      }
      uid
      profile_picture_id
      two_factor_auth_active
      signature_id
      validated
    }
  ...on ResultError {
    status_code
    message
  }
  }
}
`;

export const LOGOUT = `
  query {
    logout {
      __typename
      ... on LogoutOutput {
        result
      }
      ... on ResultError {
        status
        error_code
        message
        stack
        level
      }
    }
  }
`;

export const RESET_PASSWORD = `
  query resetPassword($username: String!) {
    __typename
    resetPassword(input: { username: $username }) {
      __typename
      result
    }
  }
`;

export const VERIFY_RECOVERY_PASSWORD_TOKEN = `
query verifyRecoveryPasswordToken($token: String!){
  verifyRecoveryPasswordToken(
    token: $token
  ){
    __typename
    ...on RecoveryResult{
      result
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const USERS_COUNT = `
query userCount{
  userCount{
    __typename
    ... on UserCount{
      count
    }
    ... on ResultError{
      status_code
      message
    }
  }
}
`;

export const USERS_COUNT_BY_ROLE = `
query userCountByRole{
  userCountByRole{
    __typename
    ... on UserCountByRole{
      role{
        id
        name
      }
      count
    }
  }
}
`;
export const GET_PROFILE_PICTURE = `
query getProfilePicture(
  $id:  Int!
){
  getProfilePicture(
    id: $id
  ){
    __typename
    ...on ProfilePicture{
      id
      filename
      mimetype
      encoding
      file
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;
export const GET_PROFILE_FILE = `
query getProfileFile(
  $id: Int!
){
  getProfileFile(
    id: $id,
  )
  {
    __typename
    ...on BaseFile{
      filename
      mimetype
      encoding
      file
        }
    ...on ResultError
    {
      status_code
      message
    }
  }
  }
`;
