import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import axios from 'axios';
import { Storage } from '@capacitor/storage';

import { ITranslation, ITranslationVersion } from './interfaces/Translation';
import { EnumsValues } from './enums/EnumsValues';

require('dotenv').config();

export const defaultLanguage = process.env.REACT_APP_FALLBACK_LNG || 'en';
const debugMode = process.env.REACT_APP_I18N_DEBUG === 'TRUE';

// Completar este objetos con las keys para tener fallback en caso de que no responda el servidor
const bundledResources = {
  en: {
    translations: {
      hello_message: 'Hi (from bundle)!',
    },
  },
};

const httpBackendInstance = new HttpBackend(null, {
  loadPath: process.env.REACT_APP_TRANSLATIONS_BACKEND_WS,
  crossDomain: true,
  request: async (_options, url, _payload, callback) => {
    try {
      const version = await axios.get(`${url}/version`, {
        withCredentials: true,
      });
      const { data }: { data: ITranslationVersion } = version;
      if (!data.version) {
        return callback(new Error('Failed to fetch translation version'), {
          status: 500,
          data: '{}',
        });
      }
      let reloadTranslations = true;
      const translationsVersions = (
        await Storage.get({
          key: EnumsValues.LocalStorageKeys.TranslationsVersions,
        })
      ).value;

      const translationsVersionValue = translationsVersions
        ? JSON.parse(translationsVersions)
        : {};

      if (!(data.language_code in translationsVersionValue)) {
        translationsVersionValue[data.language_code] = data.version;
        Storage.set({
          key: EnumsValues.LocalStorageKeys.TranslationsVersions,
          value: JSON.stringify(translationsVersionValue),
        });
      } else if (
        translationsVersionValue[data.language_code] === data.version
      ) {
        reloadTranslations = false;
      }

      const translations = (
        await Storage.get({
          key: EnumsValues.LocalStorageKeys.Translations,
        })
      ).value;
      const translationsValue = translations ? JSON.parse(translations) : {};

      if (reloadTranslations) {
        translationsVersionValue[data.language_code] = data.version;
        Storage.set({
          key: EnumsValues.LocalStorageKeys.TranslationsVersions,
          value: JSON.stringify(translationsVersionValue),
        });
        const translationAxiosData = await axios.get(url, {
          withCredentials: true,
        });
        const translationData: ITranslation = translationAxiosData.data;
        if (!translationData.version) {
          return callback(new Error('Failed to fetch translations'), {
            status: 500,
            data: '{}',
          });
        }
        translationsValue[data.language_code] = translationData;
        Storage.set({
          key: EnumsValues.LocalStorageKeys.Translations,
          value: JSON.stringify(translationsValue),
        });
        callback(null, {
          status: 200,
          data: JSON.stringify(translationData),
        });
      } else {
        if (!translationsValue[data.language_code]) {
          const translationAxiosData = await axios.get(url, {
            withCredentials: true,
          });
          const translationData = translationAxiosData.data;
          if (!translationData.version) {
            return callback(new Error('Failed to fetch translations'), {
              status: 500,
              data: '{}',
            });
          }
          translationsValue[data.language_code] = translationData;
          Storage.set({
            key: EnumsValues.LocalStorageKeys.Translations,
            value: JSON.stringify(translationsValue),
          });
          callback(null, {
            status: 200,
            data: JSON.stringify(translationData),
          });
        } else {
          callback(null, {
            status: 200,
            data: JSON.stringify(translationsValue[data.language_code]),
          });
        }
      }
    } catch (error) {
      return callback(new Error('Failed to fetch translations'), {
        status: 500,
        data: '{}',
      });
    }
  },
});

const languageDetector = new LanguageDetector();
const detectLanguageFunction = languageDetector.detect;
languageDetector.detect = (detectionOrder) => {
  const detectedLanguage = detectLanguageFunction.call(
    languageDetector, // override internal this
    detectionOrder,
  );
  if (Array.isArray(detectedLanguage)) {
    return detectedLanguage.map((detection) => detection.split('-')[0]);
  } else {
    return detectedLanguage;
  }
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(ChainedBackend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(languageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: defaultLanguage,

    load: 'languageOnly',
    returnNull: true,
    debug: debugMode,
    backend: {
      backends: [httpBackendInstance, resourcesToBackend(bundledResources)],
    },
  });

i18n.on('languageChanged', () => {});

export default i18n;
