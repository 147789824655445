import { useEffect, useState } from 'react';
import { Network } from '@capacitor/network';

interface NetworkStatus {
  connected: boolean;
  connectionType: 'wifi' | 'cellular' | 'none' | 'unknown';
}

export const useNetworkStatus = () => {
  const [networkStatus, setNetworkStatus] = useState<NetworkStatus>();

  const getConnectionStatus = async () => {
    const status = await Network.getStatus();
    setNetworkStatus(status);
  };

  useEffect(() => {
    getConnectionStatus();
  }, []);

  Network.addListener('networkStatusChange', (status) => {
    setNetworkStatus(status);
  });

  const mapStatusName = (status: any): string => {
    switch (status) {
      case 'wifi':
        return 'Wi-Fi';
      case 'cellular':
        return 'Datos Móviles';
      case 'none':
        return 'Sin Conexión';
      case 'unknown':
        return 'Desconocido';
      default:
        return 'Desconocido';
    }
  };

  return { networkStatus, mapStatusName };
};
