export const GENDER = `
query gender($id: Int!) {
  gender(id: $id) {
    __typename
    ... on Gender {
      id
      description
      activo_web
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const GENDERS = `
query genders(
  $filter: FilterGenderInput
  $orderBy: GenderOrderInput
  $skip: Int
  $take: Int
  $searchText: String
) {
  genders(
    filter: $filter
    orderBy: $orderBy
    skip: $skip
    take: $take
    searchText: $searchText
  ) {
    __typename
    ... on Gender {
      id
      description
      activo_web
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
