import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  EyeOutlined,
  PicLeftOutlined,
  PicRightOutlined,
} from '@ant-design/icons';
import ProTable, { ActionType } from '@ant-design/pro-table';
import {
  Button,
  Divider,
  Input,
  Modal,
  Tooltip,
  DatePicker,
  Select,
  Image,
  Spin,
  Upload,
  InputNumber,
} from 'antd';
import { ExporterDropdown } from '../../components/common/ABM';
import {
  useCallback,
  useContext,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { ExportableColumn, ExportableTable } from '../../shared/Exporter';
import { SorterResult } from 'antd/lib/table/interface';
import GraphqlService from '../../services/graphql/GraphqlService';
import { ABM, Authorization, MomentJS, Tools } from '../../shared';
import { showCollapseRender } from '../../shared/showCollapseRender';
import { CustomMessage } from '../../hooks';
import { ContextApp } from '../../contexts/ContextApp';
import {
  dniRegex,
  EnumsValues,
  PersonPictureType,
} from '../../enums/EnumsValues';
import useProTableForMobile from '../../hooks/useProTableForMobile';
import { ParamsType } from '@ant-design/pro-provider';
import { getPaginationArgs } from '../../shared/getPaginationArgs';
import { tooltipTrigger } from '../../shared/antdUtils';
import { IPerson } from '../../interfaces/person';
import MomentTimezoneService from '../../services/moment-timezone/MomentTimezoneService';
import { FORMAT_DATE_TIME_4 } from '../../shared/MomentJS';
import moment from 'moment';
import { IGender, INationality } from '../../interfaces';
import imageNotFound from '../../assets/imageNotFound.jpg';
import { IPersonPicture } from '../../interfaces/personPicture';
import { RcFile } from 'antd/lib/upload';
import PDFIcon from './../../assets/pdf-icon.png';
import WORDIcon from './../../assets/word-icon.png';
import { useHistory } from 'react-router';

/**
 * Configure manualmente los campos de filtrado
 */
const LIST_FILTER = [
  'firstname',
  'lastname',
  'date_of_birth_range',
  'place_of_birth',
  'gender_id',
  'nationality_id',
  'document_number',
  'cuil_cuit',
  'locality',
  'province',
  'home',
  'phone_number',
  'email',
];

const LIST_SORTER = [
  'firstname',
  'lastname',
  'date_of_birth',
  'place_of_birth',
  'gender_id',
  'nationality_id',
  'document_number',
  'cuil_cuit',
  'locality',
  'province',
  'home',
  'phone_number',
  'email',
];

/**
 * Se configura por cada ABM diferente
 */
const TITLE_PRO_TABLE = 'Formulario de consulta';
const INPUT_SEARCH_PLACEHOLDER = 'Buscar...';

export const PersonPage = () => {
  const [showModalImage, setShowModalImage] = useState({
    show: false,
    title: '',
  });
  const [personPicture, setPersonPicture] = useState({
    person_id: 0,
    picture_type_id: 0,
    picture_id: 0,
    loading: false,
    file: '',
    url: '',
  });
  const [searchText, setSearchText] = useState('');
  const [editFormValues, setEditFormValues] =
    useState<IPerson | undefined>(undefined);
  const [sorter, setSorter] = useState<string>('');
  const [dataTable, setDataTable] = useState<IPerson[]>([]);
  const [nationalities, setNationalities] = useState<INationality[]>([]);
  const [genders, setGenders] = useState<IGender[]>([]);
  const { mobileOnSizeChangeProTable, changeView, showComponent } =
    useProTableForMobile({
      layout: 'horizontal',
    });
  const { getDateUTC } = MomentTimezoneService();
  const { Query, Mutation, customRequest, customFileRequest } =
    GraphqlService();
  const {
    messageError,
    messageDeleteSuccess,
    messageDeleting,
    messageCreateSuccess,
    messageCreateError,
  } = CustomMessage();
  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});
  const { functions } = useContext(ContextApp);
  const history = useHistory();

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (actionRef.current?.reloadAndRest) {
      actionRef.current.reloadAndRest();
    }
  };

  const request = async (
    params: ParamsType & {
      pageSize?: number;
      current?: number;
      keyword?: string;
    },
  ) => {
    try {
      delete variables.current.filter;
      delete variables.current.orderBy;
      variables.current = {};
      const search = JSON.parse(JSON.stringify(params));

      if (searchText) {
        variables.current.searchText = searchText;
      } else {
        delete variables.current.searchText;
      }

      LIST_FILTER.forEach((element) => {
        try {
          if (Tools.isDefined(search[element])) {
            if (!variables.current.filter) {
              variables.current.filter = {};
            }

            variables.current.filter[element] = search[element];

            if (element === 'date_of_birth_range') {
              variables.current.filter[element] = [
                search[element][0]._d,
                search[element][1]._d,
              ];
            }
            if (element === 'document_number') {
              variables.current.filter[element] = String(search[element]);
            }
            if (element === 'cuil_cuit') {
              variables.current.filter[element] = String(search[element]);
            }
            if (element === 'phone_number') {
              variables.current.filter[element] = String(search[element]);
            }
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });
      variables.current.filter ?? (variables.current.filter = {});
      variables.current.filter.id?.length === 0 &&
        (variables.current.filter.id = undefined);

      LIST_SORTER.forEach((element) => {
        try {
          if (search.sorter[element]) {
            if (!variables.current.orderBy) {
              variables.current.orderBy = {};
            }
            variables.current.orderBy.direction =
              Tools.getTypeOrderByTableSortParam(search.sorter[element]);
            variables.current.orderBy.field = element;
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      const countPromise = customRequest({
        query: Query.personCount,
        variables: variables.current,
      }).then((data: { count: number }) => data.count);

      const { skip, take } = getPaginationArgs(
        params.pageSize || 20,
        params.current,
      );

      variables.current.skip = skip;
      variables.current.take = take;

      const dataPromise = customRequest({
        query: Query.persons,
        variables: variables.current,
      });

      const [total, data] = await Promise.all([countPromise, dataPromise]);
      setDataTable(data);
      return {
        current: params.current,
        data,
        pageSize: params.pageSize,
        success: true,
        total,
      };
    } catch (error) {
      return {
        current: params.current,
        data: [],
        pageSize: params.pageSize,
        success: false,
        total: 0,
      };
    }
  };

  const getPersonPicture = async (
    person_id: number,
    picture_type_id: number,
  ) => {
    if (!person_id || !picture_type_id) return;

    setPersonPicture({
      person_id,
      picture_type_id,
      picture_id: 0,
      loading: true,
      file: '',
      url: '',
    });
    try {
      const data: IPersonPicture = await customRequest({
        query: Query.getPersonPicture,
        variables: { person_id, picture_type_id },
      });
      const url: string = Tools.getUrlOfBase64File({
        mimetype: data.mimetype,
        fileBase64: data.file,
      });
      setPersonPicture({
        person_id,
        picture_type_id,
        picture_id: data.id,
        loading: false,
        file: data.file,
        url,
      });
    } catch (error: any) {
      //Intentional
    } finally {
      setPersonPicture((lastValue) => {
        return {
          ...lastValue,
          loading: false,
        };
      });
    }
  };

  const deletePersonPicture = async (person_picture_id: number) => {
    if (!person_picture_id) return;

    try {
      const data = await customRequest({
        query: Mutation.deletePersonPicture,
        variables: { person_picture_id },
      });
      if (data) {
        setPersonPicture((lastValue) => {
          return {
            ...lastValue,
            file: '',
            picture_id: 0,
            url: '',
          };
        });
      }
    } catch (error: any) {
      messageError({
        context: 'PersonPage.deletePersonPicture.1',
        message: error.message,
      });
    }
  };

  const deletePersonPictureModal = (person_picture_id: number) => {
    Modal.confirm({
      content: (
        <>
          ¿Seguro que desea eliminar
          {personPicture.picture_type_id === PersonPictureType.transfer_form
            ? ' la ficha de transferencia'
            : ' la imagen'}
          ?
        </>
      ),
      cancelText: 'Cancelar',
      okText: 'Aceptar',
      centered: true,
      onOk: () => {
        deletePersonPicture(person_picture_id);
        if (personPicture.picture_type_id === PersonPictureType.transfer_form) {
          deleteTransferForm(personPicture.person_id);
        }
      },
    });
  };

  const deleteTransferForm = async (person_id: number) => {
    if (!person_id) return;

    try {
      await customRequest({
        query: Mutation.deleteTransferForm,
        variables: { person_id },
      });
    } catch (error: any) {
      messageError({
        context: 'PersonPage.deleteTransferForm.1',
        message: error.message,
      });
    }
  };

  const getGenders = async () => {
    try {
      const data: IGender[] = await customRequest({
        query: Query.genders,
      });
      setGenders(data);
    } catch (error) {
      // Intentional
    }
  };

  const getNationalities = async () => {
    try {
      const data: INationality[] = await customRequest({
        query: Query.nationalities,
      });
      setNationalities(data);
    } catch (error) {
      // Intentional
    }
  };

  useEffect(() => {
    getGenders();
    getNationalities();
  }, []);

  const removeConfirmedPerson = async (value: IPerson) => {
    messageDeleting({
      context: 'PersonPage.removeConfirmedPerson.1',
      message: 'Person',
    });
    try {
      await customRequest({
        mutation: Mutation.deletePersons,
        variables: {
          ids: [value.id],
        },
      });
      messageDeleteSuccess({
        context: 'PersonPage.removeConfirmedPerson.2',
      });
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      messageError({
        context: 'PersonPage.removeConfirmedPerson.3',
        message: error.message,
      });
    }
  };

  const removePerson = (value: IPerson) => {
    Modal.confirm({
      content: (
        <>
          <div>
            ¿Seguro que desea eliminar a {value.lastname} {value.firstname} -
            DNI: {value.document_number} ?
          </div>
        </>
      ),
      cancelText: 'Cancelar',
      okText: 'Aceptar',
      onOk: () => removeConfirmedPerson(value),
    });
  };

  const columns = useCallback(
    (editMode?: boolean): ExportableColumn<IPerson>[] => {
      return [
        {
          export: false,
          dataIndex: 'id',
          title: 'Id',
          hideInTable: true,
          hideInSearch: true,
          hideInForm: true,
          type: ABM.TYPE_COLUMN.NUMBER,
          sorter: LIST_SORTER.includes('id'),
        },
        {
          export: true,
          dataIndex: 'firstname',
          title: 'Nombre',
          hideInTable: false,
          hideInSearch: false,
          hideInForm: false,
          type: ABM.TYPE_COLUMN.STRING,
          sorter: LIST_SORTER.includes('firstname'),
          render: (_, record) => record.firstname || '-',
          renderFormItem: () => (
            <Input placeholder="Ingrese nombre" minLength={1} maxLength={200} />
          ),
          formItemProps: {
            rules: [
              {
                required: true,
                message: 'Debe ingresar nombre',
              },
            ],
          },
        },
        {
          export: true,
          dataIndex: 'lastname',
          title: 'Apellido',
          hideInTable: false,
          hideInSearch: false,
          hideInForm: false,
          type: ABM.TYPE_COLUMN.STRING,
          sorter: LIST_SORTER.includes('lastname'),
          render: (_, record) => record.lastname || '-',
          renderFormItem: () => (
            <Input
              placeholder="Ingrese apellido"
              minLength={1}
              maxLength={200}
            />
          ),
          formItemProps: {
            rules: [
              {
                required: true,
                message: 'Debe ingresar apellido',
              },
            ],
          },
        },
        {
          export: true,
          dataIndex: 'date_of_birth',
          title: 'Fecha de nacimiento',
          hideInTable: false,
          hideInSearch: true,
          hideInForm: false,
          type: ABM.TYPE_COLUMN.DATE,
          sorter: LIST_SORTER.includes('date_of_birth'),
          width: 100,
          render: (_, record) =>
            getDateUTC({ element: record.date_of_birth }) || '-',
          renderDataExport: (record) =>
            getDateUTC({ element: record.date_of_birth }) || '-',
          renderFormItem: (_, render) => (
            <DatePicker
              format={FORMAT_DATE_TIME_4}
              placeholder="Seleccione fecha"
              value={editMode ? moment(render?.value).utc(false) : undefined}
            />
          ),
          formItemProps: {
            rules: [
              {
                required: true,
                message: 'Debe ingresar fecha de nacimiento',
              },
            ],
          },
        },
        {
          export: false,
          dataIndex: 'date_of_birth_range',
          title: 'Fecha de nacimiento',
          hideInTable: true,
          hideInSearch: false,
          hideInForm: true,
          type: ABM.TYPE_COLUMN.DATE,
          renderFormItem: () => (
            <DatePicker.RangePicker
              name="date_of_birth_range"
              allowEmpty={[true, true]}
              placeholder={['Fecha Desde', 'Fecha Hasta']}
              format={FORMAT_DATE_TIME_4}
            />
          ),
        },
        {
          export: true,
          dataIndex: 'place_of_birth',
          title: 'Lugar de nacimiento',
          hideInTable: false,
          hideInSearch: false,
          hideInForm: false,
          type: ABM.TYPE_COLUMN.STRING,
          sorter: LIST_SORTER.includes('place_of_birth'),
          render: (_, record) => record.place_of_birth || '-',
          renderFormItem: () => (
            <Input
              placeholder="Ingrese lugar de nacimiento"
              minLength={1}
              maxLength={200}
            />
          ),
          formItemProps: {
            rules: [
              {
                required: true,
                message: 'Debe ingresar lugar de nacimiento',
              },
            ],
          },
        },
        {
          export: true,
          dataIndex: 'gender_id',
          title: 'Género',
          hideInTable: false,
          hideInSearch: false,
          hideInForm: false,
          type: ABM.TYPE_COLUMN.STRING,
          sorter: LIST_SORTER.includes('gender_id'),
          renderDataExport: (record) => record.gender.description || '-',
          render: (_, record) => record.gender.description || '-',
          renderFormItem: () => (
            <Select
              options={genders.map((gender) => ({
                label: gender.description,
                value: editMode ? gender.id.toString() : gender.id,
              }))}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              placeholder="Seleccione genero..."
              allowClear
              showSearch
              optionFilterProp="label"
            />
          ),
          formItemProps: {
            rules: [
              {
                required: true,
                message: 'Debe ingresar género',
              },
            ],
          },
        },
        {
          export: true,
          dataIndex: 'nationality_id',
          title: 'Nacionalidad',
          hideInTable: false,
          hideInSearch: false,
          hideInForm: false,
          type: ABM.TYPE_COLUMN.STRING,
          sorter: LIST_SORTER.includes('nationality_id'),
          render: (_, record) => record.nationality.gentile || '-',
          renderDataExport: (record) => record.nationality.gentile || '-',
          renderFormItem: () => (
            <Select
              options={nationalities.map((nationality) => ({
                label: nationality.gentile,
                value: editMode ? nationality.id.toString() : nationality.id,
              }))}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              placeholder="Seleccione nacionalidad..."
              allowClear
              showSearch
              optionFilterProp="label"
            />
          ),
          formItemProps: {
            rules: [
              {
                required: true,
                message: 'Debe ingresar nacionalidad',
              },
            ],
          },
        },
        {
          export: true,
          dataIndex: 'document_number',
          title: 'DNI',
          hideInTable: false,
          hideInSearch: false,
          hideInForm: false,
          type: ABM.TYPE_COLUMN.STRING,
          sorter: LIST_SORTER.includes('document_number'),
          align: 'right',
          render: (_, record) => record.document_number || '-',
          renderFormItem: () => (
            <Input placeholder="Ingrese número de documento" />
          ),
          formItemProps: {
            rules: [
              {
                required: true,
                message: 'Debe ingresar número de documento',
              },
              {
                pattern: dniRegex,
                message: 'Formato incorrecto',
              },
            ],
          },
        },
        {
          export: true,
          dataIndex: 'cuil_cuit',
          title: 'Cuil/Cuit',
          hideInTable: false,
          hideInSearch: false,
          hideInForm: false,
          type: ABM.TYPE_COLUMN.STRING,
          sorter: LIST_SORTER.includes('cuil_cuit'),
          align: 'right',
          render: (_, record) => record.cuil_cuit || '-',
          renderFormItem: () => (
            <InputNumber
              placeholder="Ingrese número de Cuil/Cuit"
              type="number"
              controls={false}
            />
          ),
          formItemProps: {
            rules: [
              {
                pattern: /^[\d]{0,20}$/,
                message: 'Máximo 20 dígitos',
              },
            ],
          },
        },
        {
          export: true,
          dataIndex: 'home',
          title: 'Domicilio',
          hideInTable: false,
          hideInSearch: false,
          hideInForm: false,
          type: ABM.TYPE_COLUMN.STRING,
          sorter: LIST_SORTER.includes('home'),
          render: (_, record) => record.home || '-',
          renderFormItem: () => (
            <Input
              placeholder="Ingrese domicilio completo"
              minLength={1}
              maxLength={200}
            />
          ),
          formItemProps: {
            rules: [
              {
                required: true,
                message: 'Debe ingresar domicilio completo',
              },
            ],
          },
        },
        {
          export: true,
          dataIndex: 'phone_number',
          title: 'Teléfono',
          hideInTable: false,
          hideInSearch: false,
          hideInForm: false,
          sorter: LIST_SORTER.includes('phone_number'),
          render: (_, record) => record.phone_number || '-',
          renderFormItem: () => (
            <InputNumber
              placeholder="Ingrese teléfono"
              type="number"
              controls={false}
            />
          ),
          formItemProps: {
            rules: [
              {
                pattern: /^[\d]{0,30}$/,
                message: 'Máximo 30 dígitos',
              },
            ],
          },
        },
        {
          export: true,
          dataIndex: 'email',
          title: 'Email',
          hideInTable: false,
          hideInSearch: false,
          hideInForm: false,
          sorter: LIST_SORTER.includes('email'),
          render: (_, record) => record.email || '-',
          renderFormItem: () => (
            <Input placeholder="Ingrese email" minLength={1} maxLength={100} />
          ),
          formItemProps: {
            rules: [
              {
                type: 'email',
                message: 'Debe ingresar un email válido',
              },
            ],
          },
        },
        {
          title: 'Op.',
          dataIndex: 'option',
          valueType: 'option',
          fixed: 'right',
          width: 170,
          export: false,
          hideInTable: false,
          hideInSearch: true,
          hideInForm: true,
          render: (_, record) => (
            <>
              {Authorization.security(
                functions,
                EnumsValues.Functions.PersonPictureUpdate,
              ) && (
                <>
                  <Tooltip
                    key="view_or_edit_Person_image"
                    trigger={tooltipTrigger}
                    title="Ver / Modificar Imagen DNI - Frente"
                  >
                    <PicLeftOutlined
                      className="pointer"
                      onClick={() => {
                        setShowModalImage({
                          show: true,
                          title: `Imagen DNI - FRENTE ( DNI: ${record.document_number} - NOMBRE: ${record.firstname} ${record.lastname} )`,
                        });
                        getPersonPicture(
                          record.id,
                          PersonPictureType.DNI_Front,
                        );
                      }}
                    />
                  </Tooltip>
                </>
              )}
              {Authorization.security(
                functions,
                EnumsValues.Functions.PersonPictureUpdate,
              ) && (
                <>
                  <Divider type="vertical" />
                  <Tooltip
                    key="view_or_edit_Person_image"
                    trigger={tooltipTrigger}
                    title="Ver / Modificar Imagen DNI - Dorso"
                  >
                    <PicRightOutlined
                      className="pointer"
                      onClick={() => {
                        setShowModalImage({
                          show: true,
                          title: `Imagen DNI - DORSO ( DNI: ${record.document_number} - NOMBRE: ${record.firstname} ${record.lastname} )`,
                        });
                        getPersonPicture(record.id, PersonPictureType.DNI_Back);
                      }}
                    />
                  </Tooltip>
                </>
              )}
              {Authorization.security(
                functions,
                EnumsValues.Functions.PersonPictureUpdate,
              ) && (
                <>
                  <Divider type="vertical" />
                  <Tooltip
                    key="view_or_edit_Person_image"
                    trigger={tooltipTrigger}
                    title="Ver / Ficha para transferencia"
                  >
                    <EyeOutlined
                      className="pointer"
                      onClick={() => {
                        setEditFormValues(record);
                        setShowModalImage({
                          show: true,
                          title: `Ficha para transferencia ( DNI: ${record.document_number} - NOMBRE: ${record.firstname} ${record.lastname} )`,
                        });
                        getPersonPicture(
                          record.id,
                          PersonPictureType.transfer_form,
                        );
                      }}
                    />
                  </Tooltip>
                </>
              )}
              {Authorization.security(
                functions,
                EnumsValues.Functions.PersonUpdate,
              ) && (
                <>
                  <Divider type="vertical" />
                  <Tooltip
                    key="edit_Person_tooltip"
                    trigger={tooltipTrigger}
                    title="Modificar Person"
                  >
                    <EditOutlined
                      className="pointer"
                      onClick={() => {
                        setEditFormValues(record);
                        history.push({
                          pathname: '/app/add-person',
                          state: { person: record },
                        });
                      }}
                    />
                  </Tooltip>
                </>
              )}
              {Authorization.security(
                functions,
                EnumsValues.Functions.PersonDelete,
              ) && (
                <>
                  <Divider type="vertical" />
                  <Tooltip
                    key="remove_Person_tooltip"
                    trigger={tooltipTrigger}
                    title="Eliminar Person"
                  >
                    <DeleteOutlined
                      className="pointer"
                      onClick={() => removePerson(record)}
                    />
                  </Tooltip>
                </>
              )}
            </>
          ),
        },
      ];
    },
    [dataTable, editFormValues],
  );
  let LIST_FILTER_NAMES = ['Nombre', 'Apellido', 'Número de documento'];

  const exportableTable: ExportableTable<IPerson> = {
    columns: columns(),
    items: dataTable,
    filename: `Person_${MomentJS.momentDefaultFormat()}`,
  };

  const upsertPersonPicture = async (value: {
    file: any;
    person_id: number;
    picture_type_id: number;
    imageUrl: string;
    current_person_picture_id?: number;
  }) => {
    const { file, person_id, picture_type_id, current_person_picture_id } =
      value;

    try {
      await customFileRequest(
        {
          mutation: current_person_picture_id
            ? Mutation.updatePersonPicture
            : Mutation.createPersonPicture,
          variables: {
            person_id,
            picture_type_id,
            current_person_picture_id,
            file: file
              ? {
                  filename: file?.filename,
                  mimetype: file?.type,
                  encoding: 'base64',
                }
              : undefined,
          },
        },
        file
          ? [
              {
                file: file,
                path: 'variables.file',
              },
            ]
          : [],
      );

      messageCreateSuccess({
        context: 'PersonPage.upsertPersonPicture.1',
      });

      await getPersonPicture(person_id, picture_type_id);
    } catch (error: any) {
      if (error.status_code && error.message) {
        return messageError({
          context: 'PersonPage.upsertPersonPicture.2',
          message: error.message,
        });
      }
      messageCreateError({ context: 'PersonPage.upsertPersonPicture.3' });
    }
  };

  const previewImage = useMemo(() => {
    return (
      <Modal
        open={showModalImage.show}
        footer={null}
        width={800}
        bodyStyle={{ paddingBottom: 48 }}
        onCancel={() => {
          setShowModalImage({
            show: false,
            title: '',
          });
        }}
        title={showModalImage.title}
        destroyOnClose
      >
        {personPicture.loading ? (
          <div
            className="person-image-preview"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spin />
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 20,
            }}
          >
            <Image
              src={personPicture.url || ''}
              alt="Imagen DNI"
              preview={false}
              className="person-image-preview"
              fallback={imageNotFound}
              hidden={
                personPicture.picture_type_id ===
                PersonPictureType.transfer_form
              }
            />
            {personPicture.picture_type_id ===
              PersonPictureType.transfer_form &&
              personPicture.url &&
              'Ficha para transferencia cargada correctamente, puede descargarla haciendo click en el botón de abajo.'}
            {personPicture.picture_type_id ===
              PersonPictureType.transfer_form &&
              !personPicture.url && (
                <>
                  <p>No se ha cargado la ficha para transferencia.</p>
                  <Button
                    onClick={() => {
                      history.push({
                        pathname: '/app/add-tranfer',
                        state: { person: editFormValues },
                      });
                    }}
                  >
                    Cargar ficha
                  </Button>
                </>
              )}
            {personPicture.picture_type_id ===
              PersonPictureType.transfer_form &&
              personPicture.url && (
                <div style={{ display: 'flex', gap: '20px' }}>
                  <Tooltip title="Descargar archivo">
                    <Button
                      onClick={() => {
                        Tools.downloadFile({
                          filename: `person-${personPicture.person_id}-${
                            personPicture.picture_type_id
                          }-${new Date().toISOString()}.pdf`,
                          mimetype: 'application/pdf',
                          encoding: 'base64',
                          file: personPicture.file,
                        });
                      }}
                    >
                      <Image
                        preview={false}
                        src={PDFIcon}
                        style={{ width: 20, marginRight: 10 }}
                      />
                      Descargar Pdf
                    </Button>
                  </Tooltip>
                  <Tooltip title="Descargar archivo">
                    <Button
                      onClick={() => {
                        createDocx(personPicture.person_id);
                      }}
                    >
                      <Image
                        preview={false}
                        src={WORDIcon}
                        style={{ width: 20, marginRight: 10 }}
                      />
                      Descargar Word (Datos extraídos)
                    </Button>
                  </Tooltip>
                </div>
              )}
            <Button
              icon={<DeleteOutlined />}
              hidden={!!!personPicture.url}
              onClick={() => deletePersonPictureModal(personPicture.picture_id)}
            >
              Borrar
            </Button>
            <Upload
              listType="picture"
              className="upload-list-inline"
              showUploadList={true}
              multiple={false}
              maxCount={1}
              customRequest={async (uploadRequestOptions) => {
                let imageUrl = '';
                const { onSuccess, file } = uploadRequestOptions;
                const fileRc = file as RcFile;
                Tools.getBase64WithCallback(file, (fileUrl: string) => {
                  imageUrl = fileUrl;
                });
                if (onSuccess) {
                  upsertPersonPicture({
                    file: fileRc,
                    person_id: personPicture.person_id,
                    picture_type_id: personPicture.picture_type_id,
                    imageUrl,
                    current_person_picture_id: personPicture.picture_id,
                  });
                  onSuccess('');
                }
              }}
            />
          </div>
        )}
      </Modal>
    );
  }, [showModalImage, personPicture, editFormValues]);

  const createDocx = async (person_id: number) => {
    try {
      const response = await customRequest({
        mutation: Query.createGenericDocxTransferForm,
        variables: {
          person_id,
        },
      });

      if (response) {
        Tools.downloadFile({
          filename: response.filename,
          mimetype: response.mimetype,
          encoding: response.encoding,
          file: response.file,
        });
      }

      return response;
    } catch (error: any) {
      if (error.status_code && error.message) {
        return messageError({
          context: 'PersonPage.createDocx.1',
          message: error.message,
        });
      }
    }
  };

  return (
    <>
      <ProTable<IPerson>
        onSizeChange={mobileOnSizeChangeProTable}
        components={{
          table: showComponent(),
        }}
        headerTitle={TITLE_PRO_TABLE}
        actionRef={actionRef}
        rowKey="id"
        scroll={{
          x: 'max-content',
        }}
        search={{
          resetText: 'Limpiar',
          searchText: 'Buscar',
          collapseRender: showCollapseRender(columns()),
          layout: 'vertical',
        }}
        onChange={(_, _filter, _sorter) => {
          const sorterResult = _sorter as SorterResult<IPerson>;
          if (sorterResult.field) {
            setSorter(`${sorterResult.field}_${sorterResult.order}`);
          }
        }}
        onReset={() => {
          setSearchText('');
        }}
        params={{
          sorter,
        }}
        toolBarRender={() => [
          <div className="content-search-table">
            <Tooltip
              key="searchtext"
              title={'Buscar por: ' + LIST_FILTER_NAMES.join(', ')}
            >
              <Input.Search
                size="middle"
                placeholder={INPUT_SEARCH_PLACEHOLDER}
                enterButton
                value={searchText}
                onSearch={handleSearch}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
                allowClear={true}
              />
            </Tooltip>
          </div>,
          <>
            {Authorization.security(
              functions,
              EnumsValues.Functions.PersonCreate,
            ) && (
              <Button
                type="primary"
                onClick={() => history.push('/app/add-person')}
                icon={<PlusOutlined />}
              >
                Nuevo
              </Button>
            )}
          </>,
          <>
            <ExporterDropdown exportable={exportableTable} />
          </>,
          <Button onClick={changeView} className="buttonChangeProTableView">
            Cambiar Vista
          </Button>,
        ]}
        /**
         * @description este metodo debe poder ejecutar siempre la consulta al backend
         */
        request={async (params, sorter, filter) =>
          request({ ...params, sorter, filter })
        }
        columns={columns()}
      />
      {previewImage}
    </>
  );
};
