export const CREATE_TRANSFER_FORM = `mutation createTransferForm($input: CreateTransferFormInput!) {
  createTransferForm(input: $input) {
    __typename
    ... on TransferForm {
      id
      person_id
      plan_number
      provincial_part_number
      municipal_part_number
      unit_type_id
      unit_number
      header2
      header6
      header7
      header8
      observations
    }
    ... on ResultError {
      status_code
      message
    }
  }
}`;

export const UPPDATE_TRANSFER_FORM = `
mutation updateTransferForm($id:Int!,$input: UpdateTransferFormInput!) {
  updateTransferForm(id:$id,input: $input) {
    __typename
    ... on TransferForm {
      id
      person_id
      plan_number
      provincial_part_number
      municipal_part_number
      unit_type_id
      unit_number
      header2
      header6
      header7
      header8
      observations
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const DELETE_TRANSFER_FORM = `
mutation deleteTransferForm($person_id: Int!) {
  deleteTransferForm(person_id: $person_id) {
    __typename
    ... on TransferForm {
      id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
