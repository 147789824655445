import { LOGIN, LOGIN_FIREBASE } from '../query/User';
import {
  DELETE_USER,
  RESET_PASSWORD,
  SET_PASSWORD,
  UPDATE_USER,
  CREATE_USER,
  UPSERT_USER_ROLE,
  UPSERT_USER_FIREBASE,
  CREATE_PROFILE_PICTURE,
  UPDATE_PROFILE_PICTURE,
  DELETE_PROFILE_PICTURE,
  UPDATE_MY_PASSWORD,
  COMPLETE_REGISTER,
  RESEND_VALIDATE_TOKEN,
  CREATE_USER_PUBLIC,
} from './User';
import { UPDATE_SETTING } from './AppSetting';
import { CREATE_ROLE, DELETE_ROLE, UPDATE_ROLE } from './Role';
import { UPSERT_ROLE_PERMISSION } from './RolePermission';
import { REFRESH_TOKEN } from './RefreshToken';
import { UPDATE_PERMISSION } from './Permission';
import {
  DISABLE_TWO_FACTOR_AUTH,
  GENERATE_TOTP_URL,
  TWO_FACTOR_AUTH,
} from './Totp2FA';
import { CREATE_SIGNATURE, DELETE_SIGNATURE } from './Signature';
import { CREATE_PERSON, DELETE_PERSONS, UPDATE_PERSON } from './Person';
import {
  CREATE_PERSON_PICTURE,
  DELETE_PERSON_PICTURE,
  UPDATE_PERSON_PICTURE,
} from './PersonPicture';
import {
  CREATE_TRANSFER_FORM,
  DELETE_TRANSFER_FORM,
  UPPDATE_TRANSFER_FORM,
} from './TransferForm';

const Mutation = {
  createRole: { name: 'createRole', gql: CREATE_ROLE },
  createUser: { name: 'createUser', gql: CREATE_USER },
  deleteRole: { name: 'deleteRole', gql: DELETE_ROLE },
  deleteUser: { name: 'deleteUser', gql: DELETE_USER },
  login: { name: 'login', gql: LOGIN },
  loginFirebase: { name: 'loginFirebase', gql: LOGIN_FIREBASE },
  resetPassword: { name: 'resetPassword', gql: RESET_PASSWORD },
  setPassword: { name: 'setPassword', gql: SET_PASSWORD },
  updateAppSetting: { name: 'updateAppSetting', gql: UPDATE_SETTING },
  updatePermission: { name: 'updatePermission', gql: UPDATE_PERMISSION },
  updateRole: { name: 'updateRole', gql: UPDATE_ROLE },
  updateUser: { name: 'updateUser', gql: UPDATE_USER },
  upsertUserRole: { name: 'upsertUserRole', gql: UPSERT_USER_ROLE },
  upsertRolePermission: {
    name: 'upsertRolePermission',
    gql: UPSERT_ROLE_PERMISSION,
  },
  refreshToken: { name: 'refreshToken', gql: REFRESH_TOKEN },
  upsertUserFirebase: { name: 'upsertUserFirebase', gql: UPSERT_USER_FIREBASE },
  createProfilePicture: {
    name: 'createProfilePicture',
    gql: CREATE_PROFILE_PICTURE,
  },
  updateProfilePicture: {
    name: 'updateProfilePicture',
    gql: UPDATE_PROFILE_PICTURE,
  },
  deleteProfilePicture: {
    name: 'deleteProfilePicture',
    gql: DELETE_PROFILE_PICTURE,
  },
  updateMyPassword: {
    name: 'updateMyPassword',
    gql: UPDATE_MY_PASSWORD,
  },
  disableTwoFactorAuth: {
    name: 'disableTwoFactorAuth',
    gql: DISABLE_TWO_FACTOR_AUTH,
  },
  generateTotpUrl: {
    name: 'generateTotpUrl',
    gql: GENERATE_TOTP_URL,
  },
  twoFactorAuth: {
    name: 'twoFactorAuth',
    gql: TWO_FACTOR_AUTH,
  },
  createSignature: {
    name: 'createSignature',
    gql: CREATE_SIGNATURE,
  },
  deleteSignature: {
    name: 'deleteSignature',
    gql: DELETE_SIGNATURE,
  },
  completeRegister: {
    name: 'completeRegister',
    gql: COMPLETE_REGISTER,
  },
  resendValidateToken: {
    name: 'resendValidateToken',
    gql: RESEND_VALIDATE_TOKEN,
  },
  createUserPublic: {
    name: 'createUserPublic',
    gql: CREATE_USER_PUBLIC,
  },
  createPerson: {
    name: 'createPerson',
    gql: CREATE_PERSON,
  },
  deletePersons: {
    name: 'deletePersons',
    gql: DELETE_PERSONS,
  },
  updatePerson: {
    name: 'updatePerson',
    gql: UPDATE_PERSON,
  },
  deletePersonPicture: {
    name: 'deletePersonPicture',
    gql: DELETE_PERSON_PICTURE,
  },
  createPersonPicture: {
    name: 'createPersonPicture',
    gql: CREATE_PERSON_PICTURE,
  },
  updatePersonPicture: {
    name: 'updatePersonPicture',
    gql: UPDATE_PERSON_PICTURE,
  },
  createTransferForm: {
    name: 'createTransferForm',
    gql: CREATE_TRANSFER_FORM,
  },
  updateTransferForm: {
    name: 'updateTransferForm',
    gql: UPPDATE_TRANSFER_FORM,
  },
  deleteTransferForm: {
    name: 'deleteTransferForm',
    gql: DELETE_TRANSFER_FORM,
  },
};

export { Mutation };
