import { print } from 'graphql';
import {
  USERS,
  AUTH,
  LOGOUT,
  RESET_PASSWORD,
  VERIFY_RECOVERY_PASSWORD_TOKEN,
  USERS_COUNT_BY_ROLE,
  USERS_COUNT,
  GET_PROFILE_PICTURE,
  GET_PROFILE_FILE,
} from './User';
import { SETTINGS, SETTING_KEY } from './AppSetting';
import { LANGUAGES } from './Language';
import { ROLE, ROLES } from './Role';
import { PERMISSIONS } from './Permission';
import { ROLE_PERMISSION, ROLE_PERMISSIONS } from './RolePermission';
import { GET_SIGNATURE } from './Signature';
import { IQuery } from '../GraphqlService';
import { VERIFY_COMPLETE_REGISTER_BY_EMAIL_TOKEN } from './VeryTokens';
import {
  COUNT_PERSON_PER_MONTH,
  COUNT_TRANSFER_FORM_PER_MONTH,
  PERSON,
  PERSONS,
  PERSON_COUNT,
} from './Person';
import { NATIONALITIES, NATIONALITY } from './Nationality';
import { GENDER, GENDERS } from './Gender';
import { GET_PERSON_PICTURE } from './PersonPicture';
import {
  DETECT_TEXT,
  DETECT_TEXT_ON_PDF,
  DETECT_TEXT_ON_PDF_FOR_FORM_FIELD,
} from './Vision';
import {
  GET_TRANSFER_FORM,
  GET_TRANSFER_FORM_BY_PERSON_ID,
} from './TransferForm';
import {
  CREATE_DOCX_CERTIFICADO_08,
  CREATE_DOCX_ESCRITURA,
  CREATE_GENERIC_DOCX_TRANSFER_FORM,
} from './Docx';

const Query: { [property: string]: IQuery } = {
  authenticate: { name: 'authenticate', gql: AUTH },
  getAppSettingByKey: { name: 'getAppSettingByKey', gql: SETTING_KEY },
  getAppSettings: { name: 'getAppSettings', gql: SETTINGS },
  languages: { name: 'languages', gql: print(LANGUAGES) },
  logout: { name: 'logout', gql: LOGOUT },
  permissions: { name: 'permissions', gql: PERMISSIONS },
  resetPassword: { name: 'resetPassword', gql: RESET_PASSWORD },
  role: { name: 'role', gql: ROLE },
  roles: { name: 'roles', gql: ROLES },
  rolePermission: { name: 'rolePermission', gql: ROLE_PERMISSION },
  rolePermissions: { name: 'rolePermissions', gql: ROLE_PERMISSIONS },
  users: { name: 'users', gql: USERS },
  userCount: { name: 'userCount', gql: USERS_COUNT },
  userCountByRole: { name: 'userCountByRole', gql: USERS_COUNT_BY_ROLE },
  verifyRecoveryPasswordToken: {
    name: 'verifyRecoveryPasswordToken',
    gql: VERIFY_RECOVERY_PASSWORD_TOKEN,
  },
  getProfilePicture: { name: 'getProfilePicture', gql: GET_PROFILE_PICTURE },
  getProfileFile: { name: 'getProfileFile', gql: GET_PROFILE_FILE },
  getSignature: { name: 'getSignature', gql: GET_SIGNATURE },
  verifyCompleteRegisterByEmailToken: {
    name: 'verifyCompleteRegisterByEmailToken',
    gql: VERIFY_COMPLETE_REGISTER_BY_EMAIL_TOKEN,
  },
  person: {
    name: 'person',
    gql: PERSON,
  },
  persons: {
    name: 'persons',
    gql: PERSONS,
  },
  personCount: {
    name: 'personCount',
    gql: PERSON_COUNT,
  },
  nationality: {
    name: 'nationality',
    gql: NATIONALITY,
  },
  nationalities: {
    name: 'nationalities',
    gql: NATIONALITIES,
  },
  gender: {
    name: 'gender',
    gql: GENDER,
  },
  genders: {
    name: 'genders',
    gql: GENDERS,
  },
  getPersonPicture: {
    name: 'getPersonPicture',
    gql: GET_PERSON_PICTURE,
  },
  detectText: {
    name: 'detectText',
    gql: DETECT_TEXT,
  },
  detectTextOnPdf: {
    name: 'detectTextOnPdf',
    gql: DETECT_TEXT_ON_PDF,
  },
  detectTextOnPdfForFormField: {
    name: 'detectTextOnPdfForFormField',
    gql: DETECT_TEXT_ON_PDF_FOR_FORM_FIELD,
  },
  getTransferFormByPersonId: {
    name: 'getTransferFormByPersonId',
    gql: GET_TRANSFER_FORM_BY_PERSON_ID,
  },
  getTransferForm: {
    name: 'getTransferForm',
    gql: GET_TRANSFER_FORM,
  },
  createDocxCertificado08: {
    name: 'createDocxCertificado08',
    gql: CREATE_DOCX_CERTIFICADO_08,
  },
  createDocxEscritura: {
    name: 'createDocxEscritura',
    gql: CREATE_DOCX_ESCRITURA,
  },
  createGenericDocxTransferForm: {
    name: 'createGenericDocxTransferForm',
    gql: CREATE_GENERIC_DOCX_TRANSFER_FORM,
  },
  countPersonPerMonth: {
    name: 'countPersonPerMonth',
    gql: COUNT_PERSON_PER_MONTH,
  },
  countTransferFormPerMonth: {
    name: 'countTransferFormPerMonth',
    gql: COUNT_TRANSFER_FORM_PER_MONTH,
  },
};

export { Query };
