export const PERSON = `
query person($person_id: Int!) {
  person(person_id: $person_id) {
    __typename
    ... on Person {
      id
      firstname
      lastname
      date_of_birth
      place_of_birth
      gender_id
      gender {
        id
        description
        activo_web
      }
      nationality_id
      nationality {
        id
        country
        gentile
        iso
        activo_web
      }
      document_number
      cuil_cuit
      home
      phone_number
      email
      its
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const PERSONS = `
query persons(
  $filter: FilterPersonInput
  $orderBy: PersonOrderInput
  $skip: Int
  $take: Int
  $searchText: String
) {
  persons(
    filter: $filter
    orderBy: $orderBy
    skip: $skip
    take: $take
    searchText: $searchText
  ) {
    __typename
    ... on Person {
      id
      firstname
      lastname
      date_of_birth
      place_of_birth
      gender_id
      gender {
        id
        description
        activo_web
      }
      nationality_id
      nationality {
        id
        country
        gentile
        iso
        activo_web
      }
      document_number
      cuil_cuit
      home
      phone_number
      email
      its
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const PERSON_COUNT = `
query personCount($filter: FilterPersonInput, $orderBy: PersonOrderInput) {
  personCount(filter: $filter, orderBy: $orderBy) {
    __typename
    ... on Count {
      count
    }
    ... on ResultError {
      status_code
      message
    }
  }
}

`;

export const COUNT_PERSON_PER_MONTH = `
query countPersonPerMonth {
  countPersonPerMonth {
    __typename
    ... on PersonCountPerMonthAndTotal {
      personPerMonth {
        month
        month_name
        year
        count
      }
      total
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const COUNT_TRANSFER_FORM_PER_MONTH = `
query countTransferFormPerMonth {
  countTransferFormPerMonth {
    __typename
    ... on TransferFormCountPerMonthAndTotal {
      transferFormPerMonth {
        month
        month_name
        year
        count
      }
      total
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
