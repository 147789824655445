import moment from 'moment';
import { Gender, GenderId } from '../../../enums/EnumsValues';

export const useDniBackExtraction = () => {
  const dni = (data: string[]) => {
    const idargIndex = data.findIndex((item) => item.includes('IDARG'));
    if (idargIndex === -1) return '';
    const idarg = data[idargIndex];
    const formatedLine = formatLine(idarg);
    const dni = formatedLine?.split('IDARG')[1]?.split('<')[0];
    return dni?.trim();
  };

  const placeOfBirth = (data: string[]) => {
    const placeOfBirthfound = data?.find((item) => item.includes('LUGAR'));
    const dateOfEntryIntoCountryIndex = data?.findIndex((item) =>
      item.includes('INGRESO'),
    );

    if (!placeOfBirthfound) return '';

    if (dateOfEntryIntoCountryIndex !== -1) {
      //Es extranjero
      const line1 = data[dateOfEntryIntoCountryIndex - 1];
      return line1?.replace('LUGAR DE NACIMIENTO', '')?.trim();
    }

    if (placeOfBirthfound.includes('FECHA Y LUGAR')) {
      const indexPlaceOfBirth = data.findIndex((item) =>
        item.includes('FECHA Y LUGAR'),
      );
      const placeOfBirth = data[indexPlaceOfBirth + 1];
      return placeOfBirth;
    }

    const placeOfBirth = placeOfBirthfound?.split(' ');
    const placeOfBirthWithoutTitle = placeOfBirth?.slice(
      3,
      placeOfBirth.length,
    );
    return placeOfBirthWithoutTitle?.join(' ');
  };

  const birthDate = (data: string[]) => {
    let yearToUse = '';
    const idargIndex = data.findIndex((item) => item.includes('IDARG'));
    if (idargIndex === -1) return '';
    const dates = data[idargIndex + 1];
    const formatedLine = formatLine(dates);
    const nowYear = moment().year().toString()?.slice(2, 4);
    const year = formatedLine?.slice(0, 2);
    if (year > nowYear) {
      yearToUse = '19' + year;
    } else {
      yearToUse = '20' + year;
    }
    const month = formatedLine?.slice(2, 4);
    const day = formatedLine?.slice(4, 6);
    const birthDate = yearToUse + month + day;
    return moment(birthDate, 'YYYYMMDD');
  };

  const gender = (data: string[]) => {
    const idargIndex = data.findIndex((item) => item.includes('IDARG'));
    if (idargIndex === -1) return undefined;
    const dates = data[idargIndex + 1];
    const formatedLine = formatLine(dates);
    const gender = formatedLine?.slice(7, 8)?.trim();
    switch (gender?.toUpperCase()) {
      case Gender.Masculino:
        return GenderId.Masculino;
      case Gender.Femenino:
        return GenderId.Femenino;
      case Gender.Otro:
        return GenderId.Otro;
      default:
        return undefined;
    }
  };

  const cuil = (data: string[]) => {
    const cuilLine = data.find((item) => item.includes('CUIL'));
    if (!cuilLine) return '';
    const cuil = cuilLine?.split('CUIL')[1]?.replace(/[^a-zA-Z0-9 ]/g, '');
    return cuil?.trim();
  };

  const nationality = (data: string[]) => {
    const idargIndex = data.findIndex((item) => item.includes('IDARG'));
    if (idargIndex === -1) return undefined;
    const dates = data[idargIndex + 1];
    const formatedLine = formatLine(dates);
    const nationality = formatedLine?.trim()?.replace(' ', '')?.slice(15, 18);
    return nationality;
  };

  const getNames = ({
    data,
    type,
  }: {
    data: string[];
    type: 'firstname' | 'lastname';
  }): string => {
    let lastLane = data[data.length - 1];

    if (!lastLane.includes('<')) {
      lastLane = data[data.length - 2];
    }

    if (!lastLane.includes('<')) {
      return '';
    }

    const lastLaneWithEqualSpecialCharacter = formatLine(lastLane);

    const nameString =
      lastLaneWithEqualSpecialCharacter?.split('<<')[
        type === 'firstname' ? 1 : 0
      ];
    const nameStringWithoutSimbol = nameString?.replace('<', ' ');
    const nameStringWithoutSimbolAndTrimed = nameStringWithoutSimbol?.trim();
    return nameStringWithoutSimbolAndTrimed;
  };

  const firstName = (data: string[]) => {
    return getNames({ data, type: 'firstname' });
  };

  const lastName = (data: string[]) => {
    return getNames({ data, type: 'lastname' });
  };

  const formatLine = (line: string) => {
    const lineWithoutSpaces = line?.replace(/\s/g, '');
    const lineWithEqualSpecialCharacter = lineWithoutSpaces?.replace(
      /く/g,
      '<',
    );
    return lineWithEqualSpecialCharacter;
  };

  const home = (data: string[]) => {
    let homeResponse = '';
    const birthPlaceIndex = data.findIndex((item) => item.includes('LUGAR'));

    if (birthPlaceIndex === -1) return '';

    for (let index = 0; index < birthPlaceIndex; index++) {
      const element = data[index];
      homeResponse += element;
    }
    return homeResponse?.replace('DOMICILIO', '')?.trim();
  };

  return {
    dni,
    placeOfBirth,
    birthDate,
    gender,
    cuil,
    nationality,
    firstName,
    lastName,
    home,
  };
};
