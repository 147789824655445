export const CREATE_DOCX_CERTIFICADO_08 = `
query createDocxCertificado08($input: CreateDocxCertificado08Input!) {
  createDocxCertificado08(input: $input) {
    __typename
    ... on Docx {
      filename
      mimetype
      encoding
      file
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const CREATE_DOCX_ESCRITURA = `
query createDocxEscritura($input: CreateDocxEscrituraInput!) {
  createDocxEscritura(input: $input) {
    __typename
    ... on Docx {
      filename
      mimetype
      encoding
      file
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const CREATE_GENERIC_DOCX_TRANSFER_FORM = `
query createGenericDocxTransferForm($person_id: Int!) {
  createGenericDocxTransferForm(person_id: $person_id) {
    __typename
    ... on Docx {
      filename
      mimetype
      encoding
      file
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
