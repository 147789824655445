export const CREATE_PERSON = `
mutation createPerson($input: CreatePersonInput!) {
  createPerson(input: $input) {
    __typename
    ... on Person {
      id
      firstname
      lastname
      date_of_birth
      place_of_birth
      gender_id
      gender {
        id
        description
        activo_web
      }
      nationality_id
      nationality {
        id
        country
        gentile
        iso
        activo_web
      }
      document_number
      cuil_cuit
      home
      phone_number
      email
      its
    }
    ... on ResultError {
      status_code
      message
    }
  }
}

`;

export const DELETE_PERSONS = `
mutation deletePersons($ids: [Int!]!) {
  deletePersons(ids: $ids) {
    __typename
    ... on Count {
      count
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const UPDATE_PERSON = `
mutation updatePerson($id: Int!, $input: UpdatePersonInput!) {
  updatePerson(id: $id, input: $input) {
    __typename
    ... on Person {
      id
      firstname
      lastname
      date_of_birth
      place_of_birth
      gender_id
      gender {
        id
        description
        activo_web
      }
      nationality_id
      nationality {
        id
        country
        gentile
        iso
        activo_web
      }
      document_number
      cuil_cuit
      home
      phone_number
      email
      its
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
