import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { UploadFile } from 'antd/es/upload';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { CustomMessage } from '../../../hooks';
import GraphqlService from '../../../services/graphql/GraphqlService';
import { Query } from '../../../services/graphql/query';
import { FormInstance } from 'antd/es/form';
import { useDniBackExtraction } from '../../../pages/AddPerson/hooks/useDniBackExtraction';
import { IDetectedTextForFormField } from '../../../interfaces/vision';
import { INationality } from '../../../interfaces/nationality';

interface IDataExtractionProps {
  form: FormInstance;
  title: string;
  type: 'dniFront' | 'dniBack' | 'transferForm';
  fileList: UploadChangeParam<UploadFile<any>> | undefined;
  setFileList: React.Dispatch<
    React.SetStateAction<UploadChangeParam<UploadFile<any>> | undefined>
  >;
  onRemove: () => void;
  nationalities?: INationality[];
}

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const DataExtraction = (props: IDataExtractionProps) => {
  const { form, title, type, fileList, setFileList, nationalities, onRemove } =
    props;
  const [data, setData] = useState<string>('');
  const [dataPdf, setDataPdf] = useState<IDetectedTextForFormField>();
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const { customFileRequest } = GraphqlService();
  const { messageError } = CustomMessage();
  const {
    dni,
    placeOfBirth,
    birthDate,
    gender,
    cuil,
    nationality,
    firstName,
    lastName,
    home,
  } = useDniBackExtraction();

  const handlePreview = async (file: UploadFile) => {
    if (type !== 'transferForm') {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as RcFile);
      }
      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
      setPreviewTitle(
        file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
      );
    }
  };

  const uploadButton = (
    <div>
      {type !== 'transferForm' ? (
        <>
          <PlusOutlined />

          <div style={{ marginTop: 8 }}>Cargar Imagen</div>
        </>
      ) : (
        <Button icon={<UploadOutlined />}>Cargar Pdf</Button>
      )}
    </div>
  );

  const fillPersonForm = (value: string) => {
    const detections = value.split('\n');

    const IsoNationality = nationality(detections);

    const nationalityFound: INationality | undefined = nationalities?.find(
      (nationality) => nationality.iso === IsoNationality,
    );

    const formValues = form.getFieldsValue();

    form.setFieldsValue({
      place_of_birth: formValues.place_of_birth
        ? formValues.place_of_birth
        : placeOfBirth(detections),
      document_number: formValues.document_number
        ? formValues.document_number
        : dni(detections),
      cuil_cuit: formValues.cuil_cuit ? formValues.cuil_cuit : cuil(detections),
      gender_id: formValues.gender_id
        ? formValues.gender_id
        : gender(detections),
      date_of_birth: formValues.date_of_birth
        ? formValues.date_of_birth
        : birthDate(detections),
      nationality_id: formValues.nationality_id
        ? formValues.nationality_id
        : nationalityFound?.id,
      firstname: formValues.firstname
        ? formValues.firstname
        : firstName(detections),
      lastname: formValues.lastname
        ? formValues.lastname
        : lastName(detections),
      home: formValues.home ? formValues.home : home(detections),
    });
  };

  const fillTransferForm = (value: IDetectedTextForFormField) => {
    form.setFieldsValue({
      plan_number: value.plan_number,
      provincial_part_number: value.provincial_part_number,
      municipal_part_number: value.municipal_part_number,
      unit_type_id: value.unit_type_id,
      unit_number: value.unit_number,
      header2: value.header2,
      header6: value.header6,
      header7: value.header7,
      header8: value.header8,
      observations: value.observations,
    });
  };

  useEffect(() => {
    if (data && (type === 'dniBack' || type === 'dniFront')) {
      fillPersonForm(data);
    }
  }, [data]);

  useEffect(() => {
    if (dataPdf && type === 'transferForm') {
      fillTransferForm(dataPdf);
    }
  }, [dataPdf]);

  const detectText = async (file: any) => {
    try {
      const res = await customFileRequest(
        {
          mutation:
            type === 'transferForm'
              ? Query.detectTextOnPdfForFormField
              : Query.detectText,
          variables: {
            file: file
              ? {
                  filename: file?.filename,
                  mimetype: file?.type,
                  encoding: 'base64',
                }
              : undefined,
          },
        },
        file
          ? [
              {
                file: file,
                path: 'variables.file',
              },
            ]
          : [],
      );

      return res;
    } catch (error: any) {
      if (error.status_code && error.message) {
        return messageError({
          context: 'ImageDataExtraction.detectText.1',
          message: error.message,
        });
      }
    }
  };

  return (
    <>
      <h4>{title}</h4>
      {type === 'transferForm' ? (
        <p>Formato permitido: PDF</p>
      ) : (
        <p>Formato permitido: JPG / JPEG / PNG</p>
      )}
      <p>El tamaño máximo permitido del archivo es 4 MB.</p>
      <Upload
        listType={type === 'transferForm' ? 'picture' : 'picture-card'}
        onPreview={handlePreview}
        className={`upload-dni`}
        onChange={(info) => {
          setFileList(info);
        }}
        beforeUpload={(file) => {
          let isPNG = true;
          let isJPG = true;
          let isPDF = true;

          if (type !== 'transferForm') {
            isPNG = file.type === 'image/png';
            isJPG = file.type === 'image/jpeg';
            if (!isPNG && !isJPG) {
              messageError({
                context: 'ImageDataExtraction.beforeUpload.1',
                message: 'Solo se permiten archivos PNG o JPG o JPEG',
              });
              return false;
            }
          }

          if (type === 'transferForm') {
            isPDF = file.type === 'application/pdf';
            if (!isPDF) {
              messageError({
                context: 'ImageDataExtraction.beforeUpload.1',
                message: 'Solo se permiten archivos PDF',
              });
              return false;
            }
          }

          const goodFileSize = file.size < 4194304;

          if (!goodFileSize) {
            messageError({
              context: 'ImageDataExtraction.beforeUpload.2',
              message: 'El tamaño máximo permitido del archivo es 4 MB.',
            });
            return false;
          }

          return isPNG || isJPG || isPDF || goodFileSize;
        }}
        fileList={fileList?.fileList}
        onRemove={() => {
          onRemove();
          setData('');
        }}
        customRequest={async (uploadRequestOptions) => {
          const { onSuccess, file } = uploadRequestOptions;
          const fileRc = file as RcFile;
          if (onSuccess) {
            const res = await detectText(fileRc);
            if (res && type === 'transferForm') {
              setDataPdf(res);
            }
            if (res && (type === 'dniBack' || type === 'dniFront')) {
              setData(res.response);
            }
            onSuccess('');
          }
        }}
      >
        {fileList?.fileList.length ? undefined : uploadButton}
      </Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
        width={1000}
      >
        <img alt="dni" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};
