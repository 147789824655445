export const DELETE_PERSON_PICTURE = `
mutation deletePersonPicture($person_picture_id: Int!) {
  deletePersonPicture(person_picture_id: $person_picture_id) {
    __typename
    ... on PersonPicture {
      id
      filename
      mimetype
      encoding
      person_id
      picture_type_id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const CREATE_PERSON_PICTURE = `
mutation createPersonPicture(
  $file: Upload!
  $person_id: Int!
  $picture_type_id: Int!
) {
  createPersonPicture(
    file: $file
    person_id: $person_id
    picture_type_id: $picture_type_id
  ) {
    __typename
    ... on PersonPicture {
      id
      filename
      mimetype
      encoding
      person_id
      picture_type_id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const UPDATE_PERSON_PICTURE = `
mutation updatePersonPicture(
  $file: Upload!
  $person_id: Int!
  $picture_type_id: Int!
  $current_person_picture_id:Int!
) {
  updatePersonPicture(
    file: $file
    person_id: $person_id
    picture_type_id: $picture_type_id
    current_person_picture_id:$current_person_picture_id
  ) {
    __typename
    ... on PersonPicture {
      id
      filename
      mimetype
      encoding
      person_id
      picture_type_id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
