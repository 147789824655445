export const DETECT_TEXT = `query detectText($file: Upload!) {
  detectText(file: $file) {
    __typename
    ... on ResponseMessage {
      status
      response
    }
    ... on ResultError {
      status_code
      message
    }
  }
}`;

export const DETECT_TEXT_ON_PDF = `
query detectTextOnPdf($file: Upload!) {
  detectTextOnPdf(file: $file) {
    __typename
    ... on ResponseVisionMessage {
      status
      response {
        text
        page
      }
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const DETECT_TEXT_ON_PDF_FOR_FORM_FIELD = `
query detectTextOnPdfForFormField($file: Upload!) {
  detectTextOnPdfForFormField(file: $file) {
    __typename
    ... on DetectedTextForFormField {
      plan_number
      provincial_part_number
      municipal_part_number
      unit_type_id
      unit_number
      header2
      header6
      header7
      header8
      observations
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
