export const GET_PERSON_PICTURE = `
query getPersonPicture($person_id: Int! , $picture_type_id: Int!) {
  getPersonPicture(person_id: $person_id , picture_type_id:$picture_type_id) {
    __typename
    ... on PersonPicture {
      id
      filename
      mimetype
      encoding
      person_id
      picture_type_id
      file
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
